import React, { useState, useEffect } from 'react';
import puzzleData from './puzzles.json';

const WebHunt = () => {
  const WORD_LENGTH = 5;
  const TOTAL_ROWS = 6;

  const [currentRow, setCurrentRow] = useState(0);
  const [currentGuess, setCurrentGuess] = useState('');
  const [guesses, setGuesses] = useState(Array(TOTAL_ROWS).fill(''));
  const [puzzles, setPuzzles] = useState(Array(TOTAL_ROWS).fill({ clue: '', url: '', answer: '' }));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get the current date in the format "DD-MM-YYYY"
    const currentDate = new Date();
    const currentDateString = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

    // Find the puzzle set for the current date
    const currentPuzzleSet = puzzleData.find(set => set.date === currentDateString);

    if (currentPuzzleSet) {
      setPuzzles(currentPuzzleSet.puzzles);
    } else {
      // Handle the case where there are no puzzles for the current date
      console.error('No puzzles found for the current date');
      setPuzzles(Array(TOTAL_ROWS).fill({ clue: 'No puzzles available', url: '', answer: '' }));
    }
    setIsLoading(false);
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (currentGuess.length === WORD_LENGTH) {
        const newGuesses = [...guesses];
        newGuesses[currentRow] = currentGuess;
        setGuesses(newGuesses);
        if (currentRow < TOTAL_ROWS - 1) {
          setCurrentRow(currentRow + 1);
        }
        setCurrentGuess('');
      }
    } else if (event.key === 'Backspace') {
      setCurrentGuess(prev => prev.slice(0, -1));
    } else if (currentGuess.length < WORD_LENGTH && /^[A-Za-z0-9]$/.test(event.key)) {
      setCurrentGuess(prev => (prev + event.key).toUpperCase());
    }
  };

  const getLetterState = (letter, position, answer) => {
    if (!letter) return '';
    if (letter === answer[position]) return 'correct';
    if (answer.includes(letter)) return 'present';
    return 'absent';
  };

  const isUrlVisible = (rowIndex) => {
    if (rowIndex === 0) return true;
    const prevGuess = guesses[rowIndex - 1];
    return prevGuess === puzzles[rowIndex - 1]?.answer;
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentGuess, currentRow]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-gray-500 font-mono">Loading puzzles...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 px-4 py-12">
      <div className="max-w-2xl mx-auto">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-mono tracking-tight">WEB HUNT</h1>
        </div>

        {/* Current Clue */}
        <div className="bg-white border p-4 rounded-lg mb-8 font-mono text-sm text-center">
          <div className="text-gray-500 mb-1">CLUE_{currentRow + 1}</div>
          {puzzles[currentRow]?.clue || 'Loading clue...'}
        </div>

        {/* Game Grid */}
        <div className="relative bg-white border rounded-lg p-8 mb-8">
          {/* Background circuit pattern */}
          <div className="absolute inset-0 opacity-5">
            {Array(20).fill(0).map((_, i) => (
              <div
                key={i}
                className="absolute bg-gray-400"
                style={{
                  height: '1px',
                  width: `${Math.random() * 30 + 20}%`,
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 70}%`,
                  transform: `rotate(${Math.random() * 360}deg)`
                }}
              />
            ))}
          </div>

          <div className="relative space-y-4">
            {Array(TOTAL_ROWS).fill(0).map((_, rowIndex) => (
              <div key={rowIndex} className="flex justify-center">
                {/* Row identifier */}
                <div className="w-12 mr-4 flex items-center justify-end">
                  <span className="font-mono text-xs text-gray-400">
                    {(rowIndex + 1).toString().padStart(2, '0')}
                  </span>
                </div>

                {/* Letters */}
                <div className="flex gap-1">
                  {Array(WORD_LENGTH).fill(0).map((_, letterIndex) => {
                    const guessedLetter = rowIndex === currentRow
                      ? currentGuess[letterIndex]
                      : guesses[rowIndex][letterIndex];

                    const letterState = getLetterState(
                      guessedLetter,
                      letterIndex,
                      puzzles[rowIndex]?.answer || ''
                    );

                    return (
                      <div key={letterIndex} className="relative">
                        {/* Node connectors */}
                        {letterIndex < WORD_LENGTH - 1 && (
                          <div className={`
                            absolute top-1/2 -right-1 h-px w-1
                            ${rowIndex === currentRow ? 'bg-blue-400' : 'bg-gray-200'}
                            ${letterState === 'correct' ? 'bg-green-400' : ''}
                          `} />
                        )}

                        {/* Letter tile */}
                        <div className={`
                          w-12 h-12
                          font-mono
                          flex items-center justify-center
                          text-lg relative
                          border-2 rounded-sm
                          transition-all duration-200
                          ${!guessedLetter ? 'border-gray-200 bg-gray-50' : ''}
                          ${letterState === 'correct' ? 'border-green-500 bg-green-500 text-white' : ''}
                          ${letterState === 'present' ? 'border-yellow-500 bg-yellow-500 text-white' : ''}
                          ${letterState === 'absent' && guessedLetter ? 'border-gray-500 bg-gray-500 text-white' : ''}
                          ${rowIndex === currentRow ? 'shadow-sm' : ''}
                        `}>
                          {guessedLetter || ''}

                          {/* Corner dots */}
                          <div className={`
                            absolute top-0 left-0 w-1 h-1 rounded-full -translate-x-0.5 -translate-y-0.5
                            ${rowIndex === currentRow ? 'bg-blue-400' : 'bg-gray-300'}
                          `} />
                          <div className={`
                            absolute top-0 right-0 w-1 h-1 rounded-full translate-x-0.5 -translate-y-0.5
                            ${rowIndex === currentRow ? 'bg-blue-400' : 'bg-gray-300'}
                          `} />
                          <div className={`
                            absolute bottom-0 left-0 w-1 h-1 rounded-full -translate-x-0.5 translate-y-0.5
                            ${rowIndex === currentRow ? 'bg-blue-400' : 'bg-gray-300'}
                          `} />
                          <div className={`
                            absolute bottom-0 right-0 w-1 h-1 rounded-full translate-x-0.5 translate-y-0.5
                            ${rowIndex === currentRow ? 'bg-blue-400' : 'bg-gray-300'}
                          `} />
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* Status indicator */}
                <div className="w-12 ml-4 flex items-center">
                  <div className={`
                    w-2 h-2 rounded-full
                    ${rowIndex === currentRow ? 'bg-blue-400' : ''}
                    ${rowIndex < currentRow ? 'bg-green-400' : 'bg-gray-200'}
                  `} />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* URLs Section */}
        <div className="bg-white border rounded-lg p-6">
          <div className="text-xs font-mono text-gray-500 mb-4">NAVIGATION_LOG</div>
          <div className="space-y-2">
            {Array(TOTAL_ROWS).fill(0).map((_, rowIndex) => (
              <div
                key={rowIndex}
                className={`
                  transition-all duration-300
                  ${isUrlVisible(rowIndex) ? 'opacity-100' : 'opacity-0 h-0 overflow-hidden'}
                `}
              >
                <div className="flex items-center gap-3">
                  <span className="font-mono text-xs text-gray-400">
                    [{(rowIndex + 1).toString().padStart(2, '0')}]
                  </span>
                  <div className={`
                    flex-1 p-2 rounded font-mono text-sm
                    ${rowIndex === currentRow ? 'bg-blue-50 text-blue-800' : 'bg-gray-50 text-gray-600'}
                  `}>
                    {puzzles[rowIndex]?.url || ''}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebHunt;