import React from 'react';
import { FiTwitter, FiCoffee, FiMail } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const SocialIcon = ({ href, icon: Icon }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.98 }}
    className="p-2.5 rounded-full bg-stone-800/5 hover:bg-stone-800/10 transition-colors duration-500"
  >
    <Icon size={18} className="text-stone-700 transition-colors duration-500" />
  </motion.a>
);

const Footer = () => {
  return (
    <footer className="relative z-50">
      {/* Background with subtle texture */}
      <div className="absolute inset-0 bg-stone-100/95 backdrop-blur-sm" />
      {/* Zen garden-inspired top border */}
      <div className="absolute inset-x-0 top-0 h-px bg-stone-800/10" />

      <div className="max-w-6xl mx-auto px-6 py-8 relative">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-6">
          <div className="flex items-center gap-8">
            <SocialIcon
              href="https://x.com/quadcityc"
              icon={FiTwitter}
            />
            <SocialIcon
              href="https://www.buymeacoffee.com/azoodle"
              icon={FiCoffee}
            />
            <SocialIcon
              href="https://azoodle.substack.com/subscribe?email=example@gmail.com"
              icon={FiMail}
            />
          </div>

          <div className="text-center sm:text-right">
            <Link
              to="/privacy"
              className="inline-block"
            >
              <span className="text-sm tracking-wider text-stone-600 hover:text-stone-800 transition-colors duration-500">
                Privacy Policy
              </span>
              <div className="h-px w-full bg-stone-800/20 transform scale-x-0 hover:scale-x-100 transition-transform duration-500 ease-out" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;