import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// GameCard component remains unchanged
const GameCard = ({ title, description, link, accent }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="group relative"
    >
      <Link to={link}>
        <div className="relative aspect-[1/1.2] bg-stone-50 p-8 border-2 border-stone-900">
          <div className={`absolute ${accent} w-0.5 h-full top-0 -left-0.5 transform -translate-x-full`} />
          <div className="h-full flex flex-col items-center justify-between relative">
            <motion.div
              className="w-12 h-px bg-stone-900"
              animate={isHovered ? { width: 64 } : { width: 48 }}
              transition={{ duration: 0.5 }}
            />
            <div className="text-center space-y-6">
              <h3 className="text-lg text-stone-900 tracking-[0.25em]">
                {title}
              </h3>
              <motion.div
                className="h-px w-8 bg-stone-900 mx-auto"
                animate={isHovered ? { width: 32 } : { width: 8 }}
                transition={{ duration: 0.5 }}
              />
              <p className="text-xs tracking-[0.2em] text-stone-600">
                {description}
              </p>
            </div>
            <motion.div
              className="w-12 h-px bg-stone-900"
              animate={isHovered ? { width: 64 } : { width: 48 }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <AnimatePresence>
            {isHovered && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 bg-stone-900/[0.02]"
              />
            )}
          </AnimatePresence>
        </div>
      </Link>
    </motion.div>
  );
};

const HomePage = () => {
  const puzzles = [
    {
      title: "TRUST ISSUES",
      description: "Find your way through numbers",
      link: "/trustissues",
      accent: "bg-amber-600"
    },
    {
      title: "CROSSWORDS",
      description: "Where letters bloom",
      link: "/crossword",
      accent: "bg-emerald-600"
    },
    {
      title: "RARE SPECIES",
      description: "Solve in tranquility",
      link: "/rare",
      accent: "bg-indigo-600"
    },
    {
      title: "CITIES",
      description: "Clear as morning light",
      link: "/cities",
      accent: "bg-rose-600"
    },
    {
      title: "WIKI LENGTHS",
      description: "Follow the rhythm",
      link: "/wikile",
      accent: "bg-cyan-600"
    },
    {
      title: "ART STYLE",
      description: "Ripples of thought",
      link: "/museo",
      accent: "bg-violet-600"
    },
    {
      title: "MOVIES",
      description: "Find clarity in dusk",
      link: "/rater",
      accent: "bg-orange-600"
    }
  ];

  return (
    <div className="min-h-screen bg-stone-100">
      <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
        <motion.header
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-16 sm:mb-24 text-center"
        >
          <div className="space-y-12">
            <div className="space-y-8">
              <h1 className="space-y-4">
                <span className="block text-4xl sm:text-5xl tracking-[0.25em] text-stone-900">
                  FACTORDAY
                </span>
                <span className="block text-lg text-stone-600 font-light tracking-wider">
                  /ˈfæk.tərˌdeɪ/
                </span>
              </h1>

              <div className="max-w-2xl mx-auto space-y-8">
                <div className="space-y-4">
                  <h2 className="text-sm tracking-[0.2em] text-stone-900 uppercase">
                    Definitions
                  </h2>
                  <ol className="space-y-6 text-stone-600">
                    <li className="space-y-2">
                      <p className="text-sm leading-relaxed">
                        <span className="font-medium">Your Weekly Dive into Facts and Puzzles:</span> A dedicated day designed for the discovery of interesting facts and the solving of challenging puzzles, fostering mental engagement and curiosity.
                      </p>
                    </li>
                    <li>
                      <p className="text-sm leading-relaxed">
                        A time set aside for immersive learning and problem-solving, blending fun with brain-boosting activities.
                      </p>
                    </li>
                  </ol>
                </div>

                <div className="space-y-4">
                  <h2 className="text-sm tracking-[0.2em] text-stone-900 uppercase">
                    Origin
                  </h2>
                  <p className="text-sm leading-relaxed text-stone-600">
                    <span className="font-medium">Modern English</span> <span className="text-stone-500 italic">(21st Century)</span>: Derived from the words "factor" (influential element) and "day" (a recurring unit of time). Originally coined to signify a day for incorporating knowledge and cognitive challenge into routine, <span className="italic">FactorDay</span> symbolizes a special time dedicated to mental growth and enjoyment.
                  </p>
                </div>

                <div className="space-y-4">
                  <h2 className="text-sm tracking-[0.2em] text-stone-900 uppercase">
                    Usage
                  </h2>
                  <p className="text-sm italic text-stone-600">
                    "Every FactorDay, I dive into new puzzles and discover something fascinating!"
                  </p>
                </div>
              </div>
            </div>


          </div>
        </motion.header>

        <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 lg:gap-8">
          {puzzles.map((puzzle, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="w-full max-w-sm mx-auto"
            >
              <GameCard {...puzzle} />
            </motion.div>
          ))}
        </div>

        <motion.footer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className="mt-16 sm:mt-24 text-center"
        >

        </motion.footer>
      </div>
    </div>
  );
};

export default HomePage;