// ArtStyleQuiz.js
import React, { useState, useEffect } from 'react';
import { Info, X, ChevronRight } from 'lucide-react';
import { MUSEUMS, fetchRandomArtwork } from './MuseumApi';
import { ART_STYLES, STYLE_MAPPINGS } from './Museumpage';
import { OnboardingModal, InfoModal } from './MuseumUtils';


const ArtStyleQuiz = () => {
  const [currentArtwork, setCurrentArtwork] = useState(null);
  const [options, setOptions] = useState([]);
  const [score, setScore] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [gameHistory, setGameHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [showInfo, setShowInfo] = useState(false);

  const generateStyleOptions = (artwork) => {
    // Map museum classification/style to our standardized styles
    const correctStyle = STYLE_MAPPINGS[artwork.style] || artwork.style;

    // Get four unique styles including the correct one
    const allStyles = Object.keys(ART_STYLES);
    const otherStyles = allStyles.filter(style => style !== correctStyle);
    const randomStyles = otherStyles
      .sort(() => 0.5 - Math.random())
      .slice(0, 3);

    return [correctStyle, ...randomStyles].sort(() => 0.5 - Math.random());
  };

  const loadNewArtwork = async () => {
    setLoading(true);
    setError(null);

    try {
      const artwork = await fetchRandomArtwork();
      if (!artwork?.image || !artwork?.style) {
        throw new Error('Invalid artwork data');
      }

      setCurrentArtwork(artwork);
      setOptions(generateStyleOptions(artwork));
      setShowAnswer(false);
    } catch (err) {
      console.error('Error loading artwork:', err);
      setError('Failed to load artwork. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const hasPlayed = localStorage.getItem('hasPlayedArtQuiz');
    if (hasPlayed) {
      setShowOnboarding(false);
    }
    loadNewArtwork();
  }, []);

  const handleAnswer = (selectedStyle) => {
    if (showAnswer) return;

    const correctStyle = STYLE_MAPPINGS[currentArtwork.style] || currentArtwork.style;
    const isCorrect = selectedStyle === correctStyle;

    setShowAnswer(true);
    setGameHistory(prev => [...prev.slice(-4), {
      id: Date.now(),
      artwork: currentArtwork,
      selectedStyle,
      correctStyle,
      isCorrect
    }]);

    if (isCorrect) {
      setScore(s => s + 1);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-stone-100 flex items-center justify-center">
        <div className="text-center space-y-4">
          <div className="w-6 h-6 border-2 border-stone-900 border-t-transparent rounded-full animate-spin mx-auto" />
          <div className="text-xs tracking-[0.3em] text-stone-600">LOADING ARTWORK</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-stone-100 flex items-center justify-center">
        <div className="text-center space-y-4 max-w-md px-4">
          <div className="text-xl tracking-[0.2em] text-stone-900 mb-4">{error}</div>
          <button
            onClick={loadNewArtwork}
            className="px-6 py-3 text-xs tracking-[0.3em] border-2 border-stone-900
              hover:bg-stone-900 hover:text-stone-100 transition-colors"
          >
            TRY AGAIN
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-stone-100 p-4 sm:p-8 font-mono">
      {showOnboarding && (
        <OnboardingModal onClose={() => {
          setShowOnboarding(false);
          localStorage.setItem('hasPlayedArtQuiz', 'true');
        }} />
      )}
      <InfoModal show={showInfo} onClose={() => setShowInfo(false)} />

      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <header className="mb-8 sm:mb-16 space-y-8 sm:space-y-12">
          <div className="relative">
            <h1 className="text-2xl sm:text-4xl text-stone-900 text-center tracking-[0.25em] font-light">
              <span className="block text-base sm:text-lg mb-3 tracking-[0.5em]">THE</span>
              ARTISTIC STYLES
              <span className="block text-lg sm:text-xl mt-3 tracking-[0.4em] opacity-80">
                MASTER QUIZ
              </span>
            </h1>
            <button
              onClick={() => setShowInfo(true)}
              className="absolute top-0 right-0 p-2 text-stone-400 hover:text-stone-900"
              aria-label="Show game information"
            >
              <Info size={20} />
            </button>
          </div>

          <div className="flex justify-center gap-8 sm:gap-12 text-stone-900">
            <div className="text-center">
              <div className="text-xs tracking-[0.3em] mb-2 opacity-60">SCORE</div>
              <div className="text-2xl sm:text-3xl tracking-widest font-light">{score}</div>
            </div>
          </div>
        </header>

        {/* Game Area */}
        {currentArtwork && (
          <div className="mb-8 sm:mb-16">
            <p className="text-sm text-stone-600 mb-6 sm:mb-8 text-center tracking-[0.2em]">
              IDENTIFY THE ARTISTIC STYLE
            </p>

            <div className="border-2 border-stone-900 p-1">
              <div className="aspect-[4/3] relative overflow-hidden">
                <img
                  src={currentArtwork.image}
                  alt={currentArtwork.title}
                  className="w-full h-full object-contain"
                />
              </div>

              <div className="grid grid-cols-2 gap-1 mt-1">
                {options.map((style, i) => (
                  <button
                    key={`style-${i}`}
                    onClick={() => handleAnswer(style)}
                    disabled={showAnswer}
                    className={`
                      p-8 sm:p-12 text-sm tracking-[0.2em] transition-all
                      ${showAnswer && style === (STYLE_MAPPINGS[currentArtwork.style] || currentArtwork.style)
                        ? 'bg-stone-900 text-stone-100'
                        : 'hover:bg-stone-200'}
                    `}
                  >
                    {style}
                  </button>
                ))}
              </div>
            </div>

            {showAnswer && (
              <div className="mt-6 space-y-6">
                <div className="bg-stone-200 p-6">
                  <h3 className="text-sm tracking-[0.2em] mb-4">ARTWORK DETAILS</h3>
                  <div className="space-y-2 text-xs tracking-[0.1em] text-stone-600">
                    <p><strong>Title:</strong> {currentArtwork.title}</p>
                    <p><strong>Artist:</strong> {currentArtwork.artist || 'Unknown'}</p>
                    <p><strong>From:</strong> {currentArtwork.museum}</p>
                    {ART_STYLES[STYLE_MAPPINGS[currentArtwork.style]] && (
                      <div className="mt-4 pt-4 border-t border-stone-300">
                        <p className="mb-2">{ART_STYLES[STYLE_MAPPINGS[currentArtwork.style]].description}</p>
                        <p><strong>Period:</strong> {ART_STYLES[STYLE_MAPPINGS[currentArtwork.style]].period}</p>
                        <p><strong>Key Artists:</strong> {ART_STYLES[STYLE_MAPPINGS[currentArtwork.style]].keyArtists.join(', ')}</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="text-center">
                  <button
                    onClick={loadNewArtwork}
                    className="px-6 py-3 text-xs tracking-[0.3em] border-2 border-stone-900
                      hover:bg-stone-900 hover:text-stone-100 transition-colors"
                  >
                    NEXT ARTWORK
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {/* History */}
        <div className="border-t-2 border-stone-900 pt-6 sm:pt-8">
          <h2 className="flex items-center justify-center gap-4 mb-6 sm:mb-8">
            <span className="w-8 sm:w-12 h-px bg-stone-900" />
            <span className="text-xs tracking-[0.3em] opacity-60">PREVIOUS ARTWORKS</span>
            <span className="w-8 sm:w-12 h-px bg-stone-900" />
          </h2>
          <div className="space-y-1">
            {gameHistory.map((round) => (
              <div
                key={`history-${round.id}`}
                className={`
                  p-3 sm:p-4 text-xs tracking-[0.15em]
                  ${round.isCorrect
                    ? 'bg-stone-900 text-stone-100'
                    : 'border-2 border-stone-900'}
                `}
              >
                <div className="truncate">
                  {round.artwork.title} · {round.correctStyle}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtStyleQuiz;