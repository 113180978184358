import React, { useState, useEffect } from 'react';
import { AlertCircle, ChevronRight, Crown, Heart, Info, X, Camera, Globe, Leaf, Bird } from 'lucide-react';
import axios from 'axios';

// Import species data
import CriticallyEndangered from './species_data/Critically Endangered.json';
import Endangered from './species_data/Endangered.json';
import Extinct from './species_data/Extinct.json';
import ExtinctWild from './species_data/Extinct in the Wild.json';
import LeastConcern from './species_data/Least Concern.json';
import NearThreatened from './species_data/Near Threatened.json';
import Vulnerable from './species_data/Vulnerable.json';

const THREAT_LEVELS = [
  { code: 'LC', name: 'Least Concern', color: '#0D4B3E', data: LeastConcern },
  { code: 'NT', name: 'Near Threatened', color: '#1D4458', data: NearThreatened },
  { code: 'VU', name: 'Vulnerable', color: '#8B4513', data: Vulnerable },
  { code: 'EN', name: 'Endangered', color: '#7D1E1E', data: Endangered },
  { code: 'CR', name: 'Critically Endangered', color: '#4A1D46', data: CriticallyEndangered },
  { code: 'EW', name: 'Extinct in Wild', color: '#2C2C2C', data: ExtinctWild },
  { code: 'EX', name: 'Extinct', color: '#1A1A1A', data: Extinct }
];

const RaritasGame = () => {
  const [species, setSpecies] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [options, setOptions] = useState([]);
  const [score, setScore] = useState({ correct: 0, total: 0 });
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showInstructions, setShowInstructions] = useState(false);
  const [usedSpecies, setUsedSpecies] = useState(new Set());
  const [imageMetadata, setImageMetadata] = useState(null);
  const [imageSource, setImageSource] = useState(null);
  const [speciesDetails, setSpeciesDetails] = useState(null);

  // API Utility Functions
  const fetchFromWikimediaCommons = async (scientificName) => {
    try {
      const response = await axios.get('https://en.wikipedia.org/w/api.php', {
        params: {
          action: 'query',
          titles: scientificName,
          prop: 'pageimages|imageinfo',
          iiprop: 'user|extmetadata|license',
          format: 'json',
          origin: '*',
          pithumbsize: 500
        }
      });
      const pages = response.data.query.pages;
      const page = Object.values(pages)[0];
      const imageInfo = page.imageinfo?.[0];

      // Check if image has a Creative Commons license
      const license = imageInfo?.extmetadata?.License?.value || '';
      const isCreativeCommons = license.toLowerCase().includes('cc-') ||
                               license.toLowerCase().includes('creative commons');

      if (!isCreativeCommons) {
        return null;
      }

      return {
        url: page.thumbnail?.source,
        metadata: imageInfo ? {
          artist: imageInfo.user,
          license: license
        } : null
      };
    } catch (error) {
      console.error('Error fetching from Wikimedia:', error);
      return null;
    }
  };

  const fetchFromGBIF = async (scientificName) => {
    try {
      const response = await axios.get(`https://api.gbif.org/v1/species/search?q=${encodeURIComponent(scientificName)}`);
      const speciesData = response.data.results[0];
      const media = speciesData?.media?.[0];

      // Check if image has a Creative Commons license
      const license = media?.license || '';
      const isCreativeCommons = license.toLowerCase().includes('cc-') ||
                               license.toLowerCase().includes('creative commons');

      if (!isCreativeCommons) {
        return null;
      }

      return media ? {
        url: media.identifier,
        metadata: {
          creator: media.creator,
          license: license
        }
      } : null;
    } catch (error) {
      console.error('Error fetching from GBIF:', error);
      return null;
    }
  };

  const fetchFromiNaturalist = async (scientificName) => {
    try {
      const response = await axios.get(`https://api.inaturalist.org/v1/search?q=${encodeURIComponent(scientificName)}&sources=taxa`);
      const taxon = response.data.results.find(result => result.record?.taxon_photos?.[0]);
      const photo = taxon?.record?.taxon_photos?.[0]?.photo;

      // iNaturalist photos are under CC BY-NC by default
      return photo ? {
        url: photo.medium_url,
        metadata: {
          observer: photo.attribution,
          license: 'CC BY-NC',
          isPublic: true
        }
      } : null;
    } catch (error) {
      console.error('Error fetching from iNaturalist:', error);
      return null;
    }
  };

  const fetchSpeciesImage = async (scientificName) => {
    const sources = [
      { fetch: fetchFromWikimediaCommons, name: 'wikimedia' },
      { fetch: fetchFromGBIF, name: 'gbif' },
      { fetch: fetchFromiNaturalist, name: 'inaturalist' }
    ];

    for (const source of sources) {
      try {
        const result = await source.fetch(scientificName);
        if (result?.url && (!result.metadata || result.metadata.isPublic !== false)) {
          setImageSource(source.name);
          setImageMetadata({
            ...result.metadata,
            sourceName: source.name
          });
          return result.url;
        }
      } catch (error) {
        console.error(`Error fetching from ${source.name}:`, error);
        continue;
      }
    }

    setImageSource(null);
    setImageMetadata(null);
    return '/api/placeholder/800/400';
  };

  const fetchSpeciesDetails = async (scientificName) => {
    try {
      const gbifResponse = await axios.get(
        `https://api.gbif.org/v1/species/match?name=${encodeURIComponent(scientificName)}&strict=true`
      );

      if (gbifResponse.data?.usageKey) {
        const speciesKey = gbifResponse.data.usageKey;
        const [speciesInfo, speciesVernacular] = await Promise.all([
          axios.get(`https://api.gbif.org/v1/species/${speciesKey}`),
          axios.get(`https://api.gbif.org/v1/species/${speciesKey}/vernacularNames`)
        ]);

        const speciesData = {
          kingdom: speciesInfo.data.kingdom || gbifResponse.data.kingdom,
          phylum: speciesInfo.data.phylum || gbifResponse.data.phylum,
          class: speciesInfo.data.class || gbifResponse.data.class,
          order: speciesInfo.data.order || gbifResponse.data.order,
          family: speciesInfo.data.family || gbifResponse.data.family,
          genus: speciesInfo.data.genus || gbifResponse.data.genus,
          scientificName: speciesInfo.data.scientificName || scientificName,
          vernacularNames: speciesVernacular.data.results?.filter(name =>
            name.language && name.vernacularName
          ) || [],
          distribution: speciesInfo.data.distributions?.map(d =>
            d.locality || d.country
          ).filter(Boolean).join(', ') || 'Distribution information not available',
          habitat: speciesInfo.data.habitatDescription || 'Habitat information not available'
        };

        setSpeciesDetails(speciesData);
        return speciesData;
      }
    } catch (error) {
      console.error('Error fetching species details:', error);
    }

    const fallbackData = {
      scientificName,
      kingdom: 'Data unavailable',
      phylum: 'Data unavailable',
      class: 'Data unavailable',
      order: 'Data unavailable',
      family: 'Data unavailable',
      genus: 'Data unavailable',
      vernacularNames: [],
      distribution: 'Distribution information not available',
      habitat: 'Habitat information not available'
    };
    setSpeciesDetails(fallbackData);
    return fallbackData;
  };

  const getRandomSpecies = () => {
    let allSpecies = THREAT_LEVELS.flatMap(level =>
      level.data.result.map(species => ({
        ...species,
        category: level.code
      }))
    );

    allSpecies = allSpecies.filter(s => !usedSpecies.has(s.scientific_name));

    if (allSpecies.length === 0) {
      setUsedSpecies(new Set());
      allSpecies = THREAT_LEVELS.flatMap(level =>
        level.data.result.map(species => ({
          ...species,
          category: level.code
        }))
      );
    }

    const randomSpecies = allSpecies[Math.floor(Math.random() * allSpecies.length)];
    setUsedSpecies(prev => new Set([...prev, randomSpecies.scientific_name]));
    return randomSpecies;
  };

  const generateOptions = (correctAnswer) => {
    const correctThreat = THREAT_LEVELS.find(t => t.code === correctAnswer);
    const otherThreats = THREAT_LEVELS
      .filter(t => t.code !== correctAnswer)
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);
    return [...otherThreats, correctThreat].sort(() => Math.random() - 0.5);
  };

  const handleGuess = (selectedOption) => {
    const isCorrect = selectedOption.code === species.category;
    setScore(prev => ({
      correct: prev.correct + (isCorrect ? 1 : 0),
      total: prev.total + 1
    }));

    setFeedback({
      isCorrect,
      selected: selectedOption,
      correct: THREAT_LEVELS.find(t => t.code === species.category)
    });
  };

  const fetchRandomSpecies = async () => {
    setIsLoading(true);
    setIsFlipped(false);
    try {
      const speciesData = getRandomSpecies();
      const [imageUrl] = await Promise.all([
        fetchSpeciesImage(speciesData.scientific_name),
        fetchSpeciesDetails(speciesData.scientific_name)
      ]);

      const combinedData = {
        ...speciesData,
        imageUrl
      };

      setSpecies(combinedData);
      setOptions(generateOptions(combinedData.category));
      setFeedback(null);
    } catch (error) {
      console.error('Error in fetchRandomSpecies:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRandomSpecies();
  }, []);

  if (isLoading) {
    return (
      <div className="w-full max-w-5xl mx-auto p-6">
        <div className="text-center mb-8">
          <h1 className="text-4xl md:text-5xl mb-2">Raritas</h1>
          <p className="text-lg text-gray-600 italic">A Chronicle of Species Conservation</p>
        </div>
        <div className="flex items-center justify-center h-72 bg-gray-100 rounded-sm">
          <div className="text-xl text-gray-600 animate-pulse">
            Curating specimens...
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-5xl mx-auto p-6">
      <style jsx>{`
        .flip-card {
          perspective: 1000px;
        }
        .flip-card-inner {
          position: relative;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: transform 0.8s;
          transform-style: preserve-3d;
        }
        .flip-card.flipped .flip-card-inner {
          transform: rotateY(180deg);
        }
        .flip-card-front,
        .flip-card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
        }
        .flip-card-back {
          transform: rotateY(180deg);
        }
      `}</style>

      <div className="text-center mb-8">
        <h1 className="text-4xl md:text-5xl mb-2">Raritas</h1>
        <p className="text-lg text-gray-600 italic">A Chronicle of Species Conservation</p>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="bg-gray-100 rounded-sm p-4 shadow-md">
          <div className="flex items-center gap-4">
            <Crown className="w-6 h-6" />
            <div>
              <div className="text-3xl md:text-4xl">{score.correct}</div>
              <div className="text-sm tracking-widest text-gray-600 uppercase">Verified</div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 rounded-sm p-4 shadow-md">
          <div className="flex items-center gap-4">
            <Heart className="w-6 h-6" />
            <div>
              <div className="text-3xl md:text-4xl">{score.total}</div>
              <div className="text-sm tracking-widest text-gray-600 uppercase">Examined</div>
            </div>
          </div>
        </div>
      </div>

      {species && (
        <div className="space-y-6">
          <div className={`flip-card h-[500px] ${isFlipped ? 'flipped' : ''}`}>
            <div className="flip-card-inner">
              {/* Front of card (Image) */}
              <div className="flip-card-front">
                <div className="bg-gray-100 rounded-sm shadow-xl overflow-hidden h-full">
                  <div className="relative h-full" onClick={() => setIsFlipped(true)}>
                    <img
                      src={species.imageUrl}
                      alt={species.scientific_name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/30to-transparent" />
                    <div className="absolute bottom-14 left-0 right-0 p-6 md:p-8">
                      <h2 className="text-2xl md:text-3xl text-white italic">
                        {species.scientific_name}
                      </h2>
                      {species.common_name && (
                        <p className="text-lg text-white/80 mt-2">
                          {species.common_name}
                        </p>
                      )}
                    </div>
                    {imageMetadata && (
                      <div className="absolute bottom-0 left-0 right-0 p-4 bg-black/70 text-white/90 text-sm">
                        <div className="flex items-center gap-2">
                          <Camera className="w-4 h-4" />
                          <span>
                            {imageSource === 'wikimedia' && (
                              <>
                                Image from Wikimedia Commons
                                {imageMetadata.artist && ` by ${imageMetadata.artist}`}
                                {imageMetadata.license && ` (${imageMetadata.license})`}
                              </>
                            )}
                            {imageSource === 'gbif' && (
                              <>
                                Image from GBIF
                                {imageMetadata.creator && ` by ${imageMetadata.creator}`}
                                {imageMetadata.license && ` (${imageMetadata.license})`}
                              </>
                            )}
                            {imageSource === 'inaturalist' && (
                              <>
                                Image from iNaturalist
                                {imageMetadata.observer && ` by ${imageMetadata.observer}`}
                                {imageMetadata.license && ` (${imageMetadata.license})`}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="absolute top-4 right-4 bg-black/50 rounded-full px-4 py-2 text-white/90 flex items-center gap-2 backdrop-blur-sm">
                      <span className="text-sm">Click for details</span>
                      <Info className="w-4 h-4" />
                    </div>
                  </div>
                </div>
              </div>

              {/* Back of card (Species Info) */}
              <div className="flip-card-back">
                <div className="bg-gray-100 rounded-sm shadow-xl overflow-hidden h-full">
                  <div className="h-full flex flex-col">
                    <div className="p-4 border-b flex justify-between items-center bg-white/50 sticky top-0">
                      <h2 className="text-xl italic">{species.scientific_name}</h2>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsFlipped(false);
                        }}
                        className="p-2 hover:bg-gray-200 rounded-full"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>
                    <div className="flex-1 overflow-y-auto p-6">
                      {speciesDetails && (
                        <div className="space-y-6">
                          {/* Taxonomy */}
                          <section>
                            <h3 className="text-lg mb-3 flex items-center gap-2">
                              <Bird className="w-5 h-5 text-gray-700" />
                              Taxonomy
                            </h3>
                            <div className="grid grid-cols-2 gap-y-2 gap-x-4">
                              {[
                                ['Kingdom', speciesDetails.kingdom],
                                ['Phylum', speciesDetails.phylum],
                                ['Class', speciesDetails.class],
                                ['Order', speciesDetails.order],
                                ['Family', speciesDetails.family],
                                ['Genus', speciesDetails.genus]
                              ].map(([label, value]) => (
                                value && (
                                  <div key={label} className="text-sm">
                                    <span className="font-medium text-gray-700">{label}:</span>{' '}
                                    <span className="text-gray-900">{value}</span>
                                  </div>
                                )
                              ))}
                            </div>
                          </section>

                          {/* Distribution */}
                          {speciesDetails.distribution && (
                            <section>
                              <h3 className="text-lg mb-3 flex items-center gap-2">
                                <Globe className="w-5 h-5 text-gray-700" />
                                Geographic Distribution
                              </h3>
                              <p className="text-sm text-gray-700 leading-relaxed">
                                {speciesDetails.distribution}
                              </p>
                            </section>
                          )}

                          {/* Habitat */}
                          {speciesDetails.habitat && (
                            <section>
                              <h3 className="text-lg mb-3 flex items-center gap-2">
                                <Leaf className="w-5 h-5 text-gray-700" />
                                Habitat
                              </h3>
                              <p className="text-sm text-gray-700 leading-relaxed">
                                {speciesDetails.habitat}
                              </p>
                            </section>
                          )}

                          {/* Common Names */}
                          {speciesDetails.vernacularNames?.length > 0 && (
                            <section>
                              <h3 className="text-lg mb-3">Common Names</h3>
                              <div className="flex flex-wrap gap-2">
                                {speciesDetails.vernacularNames.slice(0, 5).map((name, index) => (
                                  <span
                                    key={index}
                                    className="px-2 py-1 bg-gray-200 rounded-sm text-sm text-gray-700"
                                  >
                                    {name.vernacularName}
                                    {name.language && ` (${name.language})`}
                                  </span>
                                ))}
                              </div>
                            </section>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-100 rounded-sm shadow-xl overflow-hidden">
            <div className="p-6 md:p-8">
              <h3 className="text-2xl text-gray-900 mb-8">
                Determine the conservation status:
              </h3>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                {options.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => !feedback && handleGuess(option)}
                    disabled={!!feedback}
                    className={`
                      p-4 rounded-sm text-white tracking-wide text-lg
                      transition-all duration-200 hover:shadow-lg
                      disabled:opacity-75 disabled:cursor-not-allowed
                      ${feedback && option.code === species.category ? 'ring-2 ring-green-500' : ''}
                      ${feedback && option === feedback.selected && !feedback.isCorrect ? 'ring-2 ring-red-500' : ''}
                    `}
                    style={{ backgroundColor: option.color }}
                  >
                    {option.name}
                  </button>
                ))}
              </div>

              {feedback && (
                <div className={`
                  p-4 mb-8 border-l-4
                  ${feedback.isCorrect ? 'border-green-500 bg-green-50' : 'border-red-500 bg-red-50'}
                `}>
                  <p className="text-lg">
                    {feedback.isCorrect
                      ? "A precise classification. Your expertise in conservation status is commendable."
                      : `A revision is necessary. The species is classified as ${feedback.correct.name}.`}
                  </p>
                </div>
              )}

              <div className="text-sm text-gray-600 mb-4 italic">
                Conservation status data provided by the International Union for Conservation of Nature (IUCN) Red List of Threatened Species™
              </div>

              <button
                onClick={fetchRandomSpecies}
                className="w-full p-4 bg-gray-900 text-white rounded-sm tracking-wide text-lg
                         hover:bg-gray-800 transition-all duration-200 flex items-center justify-center gap-3"
              >
                Next Specimen
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Instructions Modal */}
      {showInstructions && (
        <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-sm w-full max-w-2xl p-6 md:p-8 max-h-[90vh] overflow-y-auto">
            <div className="text-center mb-12">
              <h1 className="text-4xl md:text-5xl mb-3">Raritas</h1>
              <p className="text-lg md:text-xl text-gray-600 italic">
                A Chronicle of Species Conservation
              </p>
            </div>

            <div className="mb-12">
              <p className="text-gray-800 leading-relaxed mb-8 text-lg">
                Embark on a journey through the delicate tapestry of Earth's biodiversity,
                identifying the conservation status of remarkable species.
              </p>
              <div className="space-y-6">
                {[
                  "Examine the presented specimen and its distinguishing characteristics",
                  "Select from the IUCN Red List classification options",
                  "Receive scholarly feedback on your assessment",
                  "Progress through our carefully curated collection"
                ].map((step, index) => (
                  <div key={index} className="flex gap-4 items-start">
                    <span className="flex-shrink-0 w-8 h-8 rounded-sm bg-gray-900 text-white flex items-center justify-center">
                      {index + 1}
                    </span>
                    <p className="text-gray-800 leading-relaxed text-lg pt-1">
                      {step}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <button
              onClick={() => setShowInstructions(false)}
              className="absolute top-6 right-6 p-2 text-gray-500 hover:text-gray-900"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RaritasGame;