import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Trophy, X, ExternalLink, ArrowRight, Clock } from 'lucide-react';

const WikiGuess = () => {
  // State Management
  const [articles, setArticles] = useState([]);
  const [gameState, setGameState] = useState({
    score: 0,
    gameOver: false,
    result: null
  });
  const [uiState, setUiState] = useState({
    loading: true,
    error: null,
    showOnboarding: true,
    showHistory: false
  });
  const [gameHistory, setGameHistory] = useState([]);

  // API Configuration
  const apiParams = useMemo(() => ({
    baseUrl: 'https://en.wikipedia.org/w/api.php',
    randomParams: new URLSearchParams({
      action: 'query',
      format: 'json',
      list: 'random',
      rnlimit: '1',
      rnnamespace: '0',
      prop: 'info|extracts',
      exintro: '1',
      explaintext: '1',
      origin: '*'
    }).toString()
  }), []);

  // Fetch Functions
  const fetchRandomArticle = useCallback(async () => {
    try {
      const response = await fetch(`${apiParams.baseUrl}?${apiParams.randomParams}`);
      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      const title = data.query.random[0].title;

      const contentUrl = `${apiParams.baseUrl}?${new URLSearchParams({
        action: 'query',
        format: 'json',
        titles: title,
        prop: 'info|extracts',
        exintro: '1',
        explaintext: '1',
        origin: '*'
      })}`;

      const contentResponse = await fetch(contentUrl);
      if (!contentResponse.ok) throw new Error('Network response was not ok');

      const contentData = await contentResponse.json();
      const page = Object.values(contentData.query.pages)[0];

      if (!page || !page.length) {
        throw new Error("Invalid article data");
      }

      return {
        title: page.title,
        length: page.length,
        extract: page.extract?.slice(0, 150) + '...',
        url: `https://en.wikipedia.org/wiki/${encodeURIComponent(page.title)}`
      };
    } catch (error) {
      console.error("Failed to fetch article:", error);
      throw error;
    }
  }, [apiParams]);

  const fetchArticlePair = useCallback(async () => {
    setUiState(prev => ({ ...prev, loading: true, error: null }));

    try {
      let attempts = 0;
      let validPair = null;

      while (attempts < 3 && !validPair) {
        const article1 = await fetchRandomArticle();
        const article2 = await fetchRandomArticle();

        if (article1 && article2 &&
            article1.extract && article2.extract &&
            Math.abs(article1.length - article2.length) > 1000) {
          validPair = [article1, article2];
          break;
        }
        attempts++;
      }

      if (!validPair) {
        throw new Error("Could not find suitable articles. Please try again.");
      }

      return validPair;
    } catch (error) {
      throw error;
    }
  }, [fetchRandomArticle]);

  const fetchRandomArticles = useCallback(async () => {
    setUiState(prev => ({ ...prev, loading: true, error: null }));

    try {
      const articlePair = await fetchArticlePair();
      setArticles(articlePair);
      setUiState(prev => ({ ...prev, loading: false }));
      setGameState(prev => ({ ...prev, result: null }));
    } catch (error) {
      console.error("Failed to fetch articles:", error);
      setUiState(prev => ({
        ...prev,
        error: "Failed to load articles. Please try again.",
        loading: false
      }));
    }
  }, [fetchArticlePair]);

  // Event Handlers
  const handleGuess = useCallback((index) => {
    if (!articles[index] || !articles[1-index]) return;

    const correct = articles[index].length > articles[1-index].length;

    setGameState(prev => ({
      ...prev,
      result: correct,
      score: correct ? prev.score + 1 : prev.score,
      gameOver: !correct
    }));

    setGameHistory(prev => {
      const newHistory = [{
        articles: articles,
        guess: articles[index].title,
        correct,
        timestamp: new Date().toISOString()
      }, ...prev.slice(0, 9)];

      try {
        localStorage.setItem('wikiLength_history', JSON.stringify(newHistory));
      } catch (e) {
        console.warn('Failed to save history to localStorage:', e);
      }

      return newHistory;
    });
  }, [articles]);

  const handleRetry = useCallback(() => {
    setUiState(prev => ({ ...prev, error: null, loading: true }));
    fetchRandomArticles();
  }, [fetchRandomArticles]);

  const handleNewGame = useCallback(() => {
    setGameState(prev => ({
      ...prev,
      score: 0,
      gameOver: false,
      result: null
    }));
    fetchRandomArticles();
  }, [fetchRandomArticles]);

  // Load history from localStorage on mount
  useEffect(() => {
    try {
      const savedHistory = localStorage.getItem('wikiLength_history');
      if (savedHistory) {
        setGameHistory(JSON.parse(savedHistory));
      }
    } catch (e) {
      console.warn('Failed to load history from localStorage:', e);
    }
  }, []);

  // Initial article fetch
  useEffect(() => {
    if (!uiState.showOnboarding) {
      fetchRandomArticles();
    }
  }, [uiState.showOnboarding, fetchRandomArticles]);

  // UI Components
  const OnboardingScreen = () => (
    <div className="fixed inset-0 bg-neutral-50/98 z-50 flex items-center justify-center p-4">
      <div className="max-w-lg bg-white rounded-lg p-8 shadow-sm">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-medium tracking-tight">How to Play</h2>
          <button
            onClick={() => setUiState(prev => ({ ...prev, showOnboarding: false }))}
            className="text-neutral-500 hover:text-neutral-700"
          >
            <X size={20} />
          </button>
        </div>

        <div className="space-y-6 mb-8">
          <p className="text-neutral-600">Welcome to Wiki Length! Here's how to play:</p>
          <ol className="list-decimal list-inside space-y-3 text-neutral-600">
            <li>You'll see two random Wikipedia articles</li>
            <li>Guess which article is longer</li>
            <li>Click on the article you think has more content</li>
            <li>See how many you can get right!</li>
          </ol>
        </div>

        <button
          onClick={() => setUiState(prev => ({ ...prev, showOnboarding: false }))}
          className="w-full px-6 py-3 bg-neutral-900 text-white rounded-md hover:bg-neutral-800 transition-colors"
        >
          Start Playing
        </button>
      </div>
    </div>
  );

  const HistoryPanel = () => (
    <div className="fixed inset-0 bg-neutral-50/98 z-50 flex items-center justify-center p-4">
      <div className="max-w-2xl w-full bg-white rounded-lg p-8 shadow-sm max-h-[80vh] overflow-auto">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-medium tracking-tight">Game History</h2>
          <button
            onClick={() => setUiState(prev => ({ ...prev, showHistory: false }))}
            className="text-neutral-500 hover:text-neutral-700"
          >
            <X size={20} />
          </button>
        </div>

        {gameHistory.length === 0 ? (
          <p className="text-neutral-500">No games played yet</p>
        ) : (
          <div className="space-y-4">
            {gameHistory.map((game, i) => (
              <div key={i} className="p-4 bg-neutral-50 rounded-lg">
                <div className="flex justify-between items-start mb-2">
                  <span className={`text-sm ${game.correct ? 'text-neutral-800' : 'text-neutral-400'}`}>
                    {game.correct ? 'Correct' : 'Incorrect'}
                  </span>
                  <span className="text-xs text-neutral-400">
                    {new Date(game.timestamp).toLocaleTimeString()}
                  </span>
                </div>
                <div className="text-sm text-neutral-600">
                  <p>Your guess: {game.guess}</p>
                  <p className="text-xs mt-1">
                    {game.articles[0].title} ({game.articles[0].length.toLocaleString()} chars) vs{' '}
                    {game.articles[1].title} ({game.articles[1].length.toLocaleString()} chars)
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-neutral-50" style={{ fontFamily: "'Space Grotesk', system-ui" }}>
      {uiState.showOnboarding && <OnboardingScreen />}
      {uiState.showHistory && <HistoryPanel />}

      <main className="max-w-5xl mx-auto p-4 sm:p-6 lg:p-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-medium tracking-tight mb-3">Wiki Length</h1>
          <p className="text-neutral-500">Which article is longer?</p>
        </div>

        <div className="flex justify-center gap-8 mb-8">
          <div className="flex items-center gap-2">
            <Trophy size={18} className="text-neutral-700" />
            <span className="text-xl tracking-tight">{gameState.score}</span>
          </div>
          <button
            onClick={() => setUiState(prev => ({ ...prev, showHistory: true }))}
            className="flex items-center gap-2 text-neutral-500 hover:text-neutral-700"
          >
            <Clock size={18} />
            <span>History</span>
          </button>
        </div>

        {uiState.error && (
          <div className="text-center mb-8">
            <p className="text-red-600 mb-4">{uiState.error}</p>
            <button
              onClick={handleRetry}
              className="px-6 py-2 bg-neutral-900 text-white rounded-md hover:bg-neutral-800 transition-colors"
            >
              Try Again
            </button>
          </div>
        )}

        {uiState.loading && (
          <div className="text-center text-neutral-500">
            Loading articles...
          </div>
        )}

        {!uiState.error && !uiState.loading && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            {articles.map((article, index) => (
              <div
                key={article.title}
                onClick={() => gameState.result === null && !gameState.gameOver && handleGuess(index)}
                className={`
                  bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-all duration-300
                  ${gameState.result === null && !gameState.gameOver ? 'cursor-pointer hover:bg-neutral-50' : ''}
                  ${gameState.result !== null && articles[index].length > articles[1-index].length ? 'bg-neutral-900 text-white' : ''}
                `}
              >
                <div className="mb-4">
                  <h2 className="text-xl font-medium tracking-tight mb-3">{article.title}</h2>
                  <p className={`text-sm ${gameState.result !== null && articles[index].length > articles[1-index].length ? 'text-neutral-300' : 'text-neutral-600'} mb-4`}>
                    {article.extract}
                  </p>

                  {gameState.result !== null && (
                    <p className={`text-sm ${gameState.result !== null && articles[index].length > articles[1-index].length ? 'text-neutral-300' : 'text-neutral-500'} mb-3`}>
                      {article.length.toLocaleString()} characters
                    </p>
                  )}

                  <a
                    href={article.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`inline-flex items-center text-sm ${
                      gameState.result !== null && articles[index].length > articles[1-index].length
                      ? 'text-neutral-300 hover:text-white'
                      : 'text-neutral-500 hover:text-neutral-700'
                    }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ExternalLink size={14} className="mr-1" />
                    Read on Wikipedia
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}

        {gameState.gameOver && (
          <div className="mt-12 text-center">
            <h2 className="text-2xl font-medium tracking-tight mb-4">Game Over</h2>
            <p className="mb-6 text-neutral-600">Final Score: {gameState.score}</p>
            <button
              onClick={handleNewGame}
              className="px-6 py-2 bg-neutral-900 text-white rounded-md hover:bg-neutral-800 transition-colors"
            >
              New Game
            </button>
          </div>
        )}

        {gameState.result !== null && !gameState.gameOver && (
          <div className="mt-12 text-center">
            <button
              onClick={fetchRandomArticles}
              className="inline-flex items-center px-6 py-2 bg-neutral-900 text-white rounded-md hover:bg-neutral-800 transition-colors"
            >
              Next Round
              <ArrowRight size={16} className="ml-2" />
            </button>
          </div>
        )}
      </main>
       <footer className="w-full py-6 px-4 border-t border-neutral-200">
        <div className="max-w-5xl mx-auto text-center">
          <div className="flex flex-col items-center justify-center gap-2 text-sm text-neutral-500">
            <p>
              All article content is from{' '}
              <a
                href="https://www.wikipedia.org"
                target="_blank"
                rel="noopener noreferrer"
                className="text-neutral-700 hover:text-neutral-900 underline underline-offset-2"
              >
                Wikipedia
              </a>
              {' '}via the MediaWiki API
            </p>
            <p className="text-xs">
              Content is available under{' '}
              <a
                href="https://creativecommons.org/licenses/by-sa/3.0/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-neutral-700 hover:text-neutral-900 underline underline-offset-2"
              >
                CC BY-SA 3.0
              </a>
              {' '}unless otherwise noted
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default React.memo(WikiGuess);