// components/Modals.js
import React, { useState } from 'react';
import { X, ChevronRight } from 'lucide-react';

export const OnboardingModal = ({ onClose }) => {
  const [step, setStep] = useState(0);

  const steps = [
    {
      id: 'welcome',
      title: "WELCOME TO THE ARTISTRY",
      content: "Test your knowledge of art history through a journey across different artistic styles and movements.",
      image: "🎨"
    },
    {
      id: 'learn',
      title: "LEARN AS YOU PLAY",
      content: "Each artwork comes with insights about its style, period, and influential artists of the movement.",
      image: "📚"
    },
    {
      id: 'explore',
      title: "EXPLORE MUSEUMS",
      content: "Discover masterpieces from renowned museums around the world.",
      image: "🏛️"
    }
  ];

  return (
    <div className="fixed inset-0 bg-stone-900/90 flex items-center justify-center p-4 z-50">
      <div className="bg-stone-100 max-w-lg w-full p-8 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-stone-400 hover:text-stone-900"
          aria-label="Close onboarding"
        >
          <X size={20} />
        </button>

        <div className="space-y-6 mb-8">
          <div className="text-center text-4xl mb-4">{steps[step].image}</div>
          <h2 className="text-xl tracking-[0.2em] text-center font-light">
            {steps[step].title}
          </h2>
          <p className="text-sm tracking-wide text-center leading-relaxed text-stone-600">
            {steps[step].content}
          </p>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex gap-2">
            {steps.map((s, i) => (
              <div
                key={s.id}
                className={`w-2 h-2 rounded-full ${
                  i === step ? 'bg-stone-900' : 'bg-stone-300'
                }`}
              />
            ))}
          </div>

          {step < steps.length - 1 ? (
            <button
              onClick={() => setStep(s => s + 1)}
              className="px-6 py-2 text-xs tracking-[0.2em] border-2 border-stone-900
                hover:bg-stone-900 hover:text-stone-100 transition-colors flex items-center gap-2"
            >
              NEXT <ChevronRight size={14} />
            </button>
          ) : (
            <button
              onClick={onClose}
              className="px-6 py-2 text-xs tracking-[0.2em] bg-stone-900 text-stone-100
                hover:bg-stone-800 transition-colors"
            >
              START EXPLORING
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const InfoModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-stone-900/90 flex items-center justify-center p-4 z-50">
      <div className="bg-stone-100 max-w-lg w-full p-8 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-stone-400 hover:text-stone-900"
          aria-label="Close info"
        >
          <X size={20} />
        </button>

        <div className="space-y-6">
          <h2 className="text-xl tracking-[0.2em] text-center font-light">ABOUT THE GAME</h2>
          <div className="space-y-4 text-sm tracking-wide text-stone-600">
            <p>Test your knowledge of art history by identifying artistic styles and movements.</p>
            <p>Each artwork is sourced from prestigious museums including:</p>
            <ul className="list-disc ml-4 space-y-1">
              <li>The Metropolitan Museum of Art</li>
              <li>The Cleveland Museum of Art</li>
              <li>Harvard Art Museums</li>
              <li>The Walters Art Museum</li>
            </ul>
            <p>Learn about different artistic styles, their characteristics, and the artists who defined them.</p>
          </div>
        </div>
      </div>
    </div>
  );
};