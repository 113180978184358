// artStyles.js
export const ART_STYLES = {
  'Impressionism': {
    description: 'Characterized by small, thin brush strokes, emphasis on light and its changing qualities, and movement.',
    period: '1867-1886',
    keyArtists: ['Claude Monet', 'Pierre-Auguste Renoir', 'Edgar Degas', 'Camille Pissarro']
  },
  'Renaissance': {
    description: 'Marked by renewed interest in classical antiquity, scientific observation, and individual achievement.',
    period: '1400-1600',
    keyArtists: ['Leonardo da Vinci', 'Michelangelo', 'Raphael', 'Sandro Botticelli']
  },
  'Baroque': {
    description: 'Dramatic, emotional, and grandiose style emphasizing movement and ornate details.',
    period: '1600-1750',
    keyArtists: ['Caravaggio', 'Rembrandt', 'Peter Paul Rubens', 'Diego Velázquez']
  },
  'Realism': {
    description: 'Accurate, detailed, unembellished depiction of nature and contemporary life.',
    period: '1840-1880',
    keyArtists: ['Gustave Courbet', 'Jean-François Millet', 'Honoré Daumier']
  },
  'Romanticism': {
    description: 'Emphasis on emotion, nature, and individualism, often featuring dramatic and exotic subjects.',
    period: '1780-1850',
    keyArtists: ['Eugène Delacroix', 'J.M.W. Turner', 'Caspar David Friedrich']
  },
  'Gothic': {
    description: 'Medieval style characterized by pointed arches, religious themes, and elaborate decoration.',
    period: '1150-1500',
    keyArtists: ['Giotto', 'Jan van Eyck', 'Hieronymus Bosch']
  },
  'Neoclassicism': {
    description: 'Revival of classical art principles emphasizing simplicity and symmetry.',
    period: '1750-1850',
    keyArtists: ['Jacques-Louis David', 'Jean-Auguste-Dominique Ingres', 'Antonio Canova']
  },
  'Post-Impressionism': {
    description: 'Built on Impressionism while rejecting its limitations, emphasizing geometric forms and unnatural color.',
    period: '1885-1910',
    keyArtists: ['Vincent van Gogh', 'Paul Cézanne', 'Georges Seurat', 'Paul Gauguin']
  },
  'Modernism': {
    description: 'Break with traditional forms of art, architecture, literature, and religious faith.',
    period: '1860-1975',
    keyArtists: ['Pablo Picasso', 'Henri Matisse', 'Wassily Kandinsky']
  },
  'Rococo': {
    description: 'Light, elegant, and ornate style featuring curved forms and pale colors.',
    period: '1720-1780',
    keyArtists: ['Jean-Honoré Fragonard', 'François Boucher', 'Antoine Watteau']
  }
};

// Mapping museum classifications to our standardized styles
export const STYLE_MAPPINGS = {
  // Met Museum mappings
  'Paintings': 'Renaissance',
  'European Paintings': 'Renaissance',
  'Medieval Art': 'Gothic',
  'Modern Art': 'Modernism',
  'Impressionist': 'Impressionism',
  'Post-Impressionist': 'Post-Impressionism',
  'Baroque': 'Baroque',
  'American Paintings': 'Realism',
  
  // Cleveland Museum mappings
  'Romanesque': 'Gothic',
  'Gothic Style': 'Gothic',
  'Renaissance Style': 'Renaissance',
  'Baroque Style': 'Baroque',
  'Impressionist Style': 'Impressionism',
  'Modern Style': 'Modernism',
  
  // Harvard Museum mappings
  'Renaissance Revival': 'Renaissance',
  'Gothic Revival': 'Gothic',
  'Impressionist School': 'Impressionism',
  'Baroque Period': 'Baroque',
  
  // Walters Museum mappings
  'Gothic Art': 'Gothic',
  'Renaissance Art': 'Renaissance',
  'Baroque Art': 'Baroque',
  'Impressionism Movement': 'Impressionism',
  
  // Generic mappings
  'Classical': 'Neoclassicism',
  'Romantic': 'Romanticism',
  'Modern': 'Modernism',
  'Contemporary': 'Modernism'
};