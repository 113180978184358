import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const navLinks = [
    {/*{ name: 'Explore', path: '/explore' },*/}

  ];

  const isActive = (path) => location.pathname === path;

  return (
    <header className="relative z-50">
      {/* Background with subtle texture */}
      <div className="absolute inset-0 bg-stone-50/95 backdrop-blur-sm" />
      {/* Zen garden-inspired bottom border */}
      <div className="absolute inset-x-0 bottom-0 h-px bg-stone-800/10" />

      <div className="max-w-6xl mx-auto px-6 py-6 relative">
        <nav className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="group">
            <motion.div
              whileHover={{ scale: 1.01 }}
              className="relative"
            >
              <span className="text-2xl font-extralight text-stone-800 tracking-widest relative z-10">
                Factorday
              </span>
              <div className="absolute -bottom-1 left-0 w-full h-px bg-stone-800/20 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 ease-out" />
            </motion.div>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-12">
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.path}
                className="group relative py-2"
              >
                <span className={`text-sm tracking-wider transition-colors duration-500 ${
                  isActive(link.path) ? 'text-stone-900' : 'text-stone-600 group-hover:text-stone-800'
                }`}>
                  {link.name}
                </span>
                <div className={`absolute -bottom-1 left-0 w-full h-px bg-stone-800/20 transform origin-left transition-transform duration-500 ease-out ${
                  isActive(link.path) ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                }`} />
              </Link>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="p-2 rounded-full hover:bg-stone-100 transition-colors duration-300"
            >
              {isMobileMenuOpen ? (
                <FiX className="w-5 h-5 text-stone-800" />
              ) : (
                <FiMenu className="w-5 h-5 text-stone-800" />
              )}
            </button>
          </div>
        </nav>

        {/* Mobile Menu */}
        <motion.div
          initial="closed"
          animate={isMobileMenuOpen ? "open" : "closed"}
          variants={{
            open: { opacity: 1, height: "auto" },
            closed: { opacity: 0, height: 0 }
          }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="md:hidden overflow-hidden"
        >
          <div className="py-6 space-y-6">
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.path}
                onClick={() => setIsMobileMenuOpen(false)}
                className={`block py-2 transition-colors duration-300 ${
                  isActive(link.path)
                    ? 'text-stone-900'
                    : 'text-stone-600 hover:text-stone-800'
                }`}
              >
                <span className="text-sm tracking-wider">{link.name}</span>
              </Link>
            ))}
          </div>
        </motion.div>
      </div>
    </header>
  );
};

export default Header;