import React from 'react';
import { Shield, Globe, Database, PuzzleIcon, AlertTriangle } from 'lucide-react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-stone-50 py-8 px-4">
      <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-sm p-8 sm:p-12">
        <h1 className="text-4xl font-bold text-stone-900 text-center mb-6 pb-4 border-b border-stone-200">
          Privacy Policy
        </h1>

        <div className="text-center text-stone-600 italic mb-8">
          Last Updated: {new Date().toLocaleDateString()}
        </div>

        <section className="mb-12">
          <p className="text-stone-600 mb-4">
            This Privacy Policy describes how your information is collected, used, and shared when you visit or use our website and its games. We are committed to protecting your privacy while providing engaging gaming experiences.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-stone-900 mb-6 flex items-center gap-3">
            <AlertTriangle className="w-6 h-6" />
            Content Advisory
          </h2>
          <div className="space-y-4 text-stone-600">
            <p>
              Please note that our museum art quiz game contains classical artworks that may include:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Artistic depictions of nudity in classical and historical paintings</li>
              <li>Mature themes as represented in historical artwork</li>
              <li>Content that may not be suitable for all ages</li>
            </ul>
            <p className="mt-4 font-medium">
              Parental discretion is advised when allowing children to access the museum art sections of the website.
            </p>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-stone-900 mb-6 flex items-center gap-3">
            <Database className="w-6 h-6" />
            Information We Collect
          </h2>
          <div className="space-y-4 text-stone-600">
            <h3 className="text-xl font-medium text-stone-800 mb-3">Server Analytics:</h3>
            <p>
              Our website uses server-side analytics provided by our webserver to monitor traffic and usage patterns. This may include:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>IP Address:</strong> Collected to analyze user locations and improve our services</li>
              <li><strong>Page Views and Session Duration:</strong> Information about how long users stay on each page</li>
              <li><strong>Browser and Device Data:</strong> Basic information about the type of browser and device used to access the site</li>
            </ul>
            <p className="mt-4">
              These analytics are anonymized and aggregated. They do not include personally identifiable information.
            </p>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-stone-900 mb-6 flex items-center gap-3">
            <PuzzleIcon className="w-6 h-6" />
            PuzzleMe Crosswords
          </h2>
          <div className="space-y-4 text-stone-600">
            <p>
              Our crossword puzzles are powered by PuzzleMe, a third-party service that maintains its own privacy policy and analytics system. When you use our crossword features:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>You are interacting with PuzzleMe's embedded software</li>
              <li>PuzzleMe collects and processes its own analytics data</li>
              <li>PuzzleMe's privacy policy governs their data collection and use</li>
              <li>For details about how PuzzleMe handles your data, please refer to their privacy policy</li>
            </ul>
            <p className="mt-4 text-sm italic">
              Note: PuzzleMe's data collection and analytics are separate from and in addition to our website's basic server analytics.
            </p>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-stone-900 mb-6 flex items-center gap-3">
            <Globe className="w-6 h-6" />
            Local Storage and Cookies
          </h2>
          <div className="space-y-4 text-stone-600">
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Local Storage:</strong> Our games may store data (such as game progress and settings) in your browser's local storage. This data remains on your device and is not shared with us.</li>
              <li><strong>Server-Side Cookies:</strong> Our webserver's analytics may use cookies to track basic session data. These cookies do not collect personal information.</li>
              <li><strong>PuzzleMe Cookies:</strong> The crossword feature may use additional cookies and storage as required by PuzzleMe's service.</li>
            </ul>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-stone-900 mb-6 flex items-center gap-3">
            <Shield className="w-6 h-6" />
            Data Usage and Security
          </h2>
          <div className="space-y-4 text-stone-600">
            <p>The analytics data collected by our server is used solely for:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Understanding how users interact with the website</li>
              <li>Improving the overall user experience</li>
              <li>Monitoring website performance</li>
            </ul>
            <p className="mt-4">
              All game progress and settings (except for crosswords) are stored locally on your device. We do not collect, store, or transmit this information to our servers.
            </p>
          </div>
        </section>

        <div className="text-center text-stone-600">
          <p>For questions about this Privacy Policy, please contact:</p>
          <p className="mt-2">
            <a href="mailto:quadcitycollective@gmail.com" className="text-stone-900 hover:underline">
              quadcitycollective@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;