import React, { useState, useEffect } from 'react';
import { Info, X, ChevronRight } from 'lucide-react';

const characters = {
  ambitious: {
    name: "The Ambitious One",
    description: "Always looking for advancement opportunities",
    strategy: "defects more when winning",
    cooperateText: "Cooperate",
    defectText: "Compete",
    messages: {
      cooperate: ["Let's collaborate on this project", "Team success is important"],
      defect: ["Sorry, I need this win", "It's just business"],
    }
  },
  mentor: {
    name: "The Mentor",
    description: "Experienced and supportive, but expects reciprocity",
    strategy: "forgiving but remembers betrayals",
    cooperateText: "Help",
    defectText: "Teach Lesson",
    messages: {
      cooperate: ["I'm here to help you grow", "Let's work together"],
      defect: ["You need to learn this lesson", "Trust needs to be earned"],
    }
  },
  loyal: {
    name: "The Loyal Friend",
    description: "Values trust above all",
    strategy: "usually cooperates but betrayal hurts deeply",
    cooperateText: "Support",
    defectText: "Betray",
    messages: {
      cooperate: ["Friends stick together!", "I've got your back"],
      defect: ["I thought we were friends...", "This really hurts"],
    }
  },
  dramatic: {
    name: "The Drama Queen",
    description: "Unpredictable and emotional",
    strategy: "random with emotional responses",
    cooperateText: "Be Nice",
    defectText: "Create Drama",
    messages: {
      cooperate: ["BFFs forever!", "You're the best!"],
      defect: ["I can't believe you!", "How could you?"],
    }
  },
  nerd: {
    name: "The Academic",
    description: "Analytical and pattern-focused",
    strategy: "cooperates with consistent partners",
    cooperateText: "Share",
    defectText: "Keep",
    messages: {
      cooperate: ["Knowledge should be shared", "Let's study together"],
      defect: ["I need to maintain my edge", "This is a competitive world"],
    }
  },
  slacker: {
    name: "The Free Spirit",
    description: "Unreliable but occasionally surprising",
    strategy: "mostly defects but random cooperation",
    cooperateText: "Help Out",
    defectText: "Slack Off",
    messages: {
      cooperate: ["Thanks for the help!", "You're a lifesaver"],
      defect: ["I forgot to do it...", "Maybe next time"],
    }
  }
};

const MinimalFigure = ({ isAi, name }) => (
  <div className="text-center">
    <svg viewBox="0 0 40 60" className="w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 mx-auto">
      <circle cx="20" cy="15" r="8" fill="none" stroke="currentColor" strokeWidth="1.5" className="text-neutral-800"/>
      <line x1="20" y1="23" x2="20" y2="45" stroke="currentColor" strokeWidth="1.5" className="text-neutral-800"/>
      <line x1="20" y1="45" x2="12" y2="58" stroke="currentColor" strokeWidth="1.5" className="text-neutral-800"/>
      <line x1="20" y1="45" x2="28" y2="58" stroke="currentColor" strokeWidth="1.5" className="text-neutral-800"/>
    </svg>
    <div className="mt-2 text-xs sm:text-sm text-neutral-600">{name}</div>
  </div>
);

const Tutorial = ({ onClose }) => (
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
    <div className="bg-stone-50 p-6 max-w-md relative rounded-lg">
      <button
        onClick={onClose}
        className="absolute right-4 top-4 text-neutral-500 hover:text-neutral-700"
      >
        <X size={20} />
      </button>
      <h2 className="text-xl font-normal text-neutral-800 mb-4">How to Play</h2>
      <div className="space-y-4 text-neutral-600">
        <div>
          <h3 className="font-medium mb-2">Basic Rules</h3>
          <p className="text-sm">Each round, both you and your opponent choose to either cooperate or not. Different characters have different strategies and reactions!</p>
        </div>
        <div>
          <h3 className="font-medium mb-2">Outcomes</h3>
          <div className="space-y-2 text-sm">
            <p>• Both cooperate: Each gain 30</p>
            <p>• Both defect: Each lose 10</p>
            <p>• One defects, one cooperates: Defector gains 40, cooperator loses 20</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Onboarding = ({ onComplete }) => {
  const [step, setStep] = useState(0);
  const steps = [
    {
      title: "Welcome to the Trust Game!",
      content: "Meet different characters and learn about trust and cooperation. Each character has their own personality and strategy!",
      image: (
        <div className="flex justify-center items-center h-32">
          <div className="flex gap-8 items-center">
            <MinimalFigure isAi={false} name="You" />
            <span className="text-2xl text-neutral-400">⚡</span>
            <MinimalFigure isAi={true} name="Them" />
          </div>
        </div>
      )
    },
    {
      title: "How to Play",
      content: "Each round, both you and your opponent choose whether to cooperate or not. Your choices affect your scores!",
      image: (
        <div className="flex justify-center items-center h-32">
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div className="text-center p-2 border border-green-200 bg-green-50 rounded">
              Both Cooperate:<br />+30 each
            </div>
            <div className="text-center p-2 border border-red-200 bg-red-50 rounded">
              Both Defect:<br />-10 each
            </div>
            <div className="text-center p-2 border border-yellow-200 bg-yellow-50 rounded">
              You Cooperate, They Don't:<br />-20 / +40
            </div>
            <div className="text-center p-2 border border-yellow-200 bg-yellow-50 rounded">
              You Don't, They Do:<br />+40 / -20
            </div>
          </div>
        </div>
      )
    },
    {
      title: "Know Your Opponent",
      content: "Each character has their own personality and strategy. Some are forgiving, others hold grudges. Choose wisely!",
      image: (
        <div className="flex justify-center items-center gap-6 h-32">
          {Object.entries(characters).slice(0, 3).map(([key, char]) => (
            <div key={key} className="text-center">
              <div className="w-12 h-12 rounded-full bg-neutral-100 flex items-center justify-center mb-2">
                <MinimalFigure isAi={true} />
              </div>
              <div className="text-xs">{char.name}</div>
            </div>
          ))}
        </div>
      )
    }
  ];

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="text-center mb-6">
          <h2 className="text-xl font-medium text-neutral-800 mb-2">
            {steps[step].title}
          </h2>
          {steps[step].image}
          <p className="text-neutral-600 mt-4">
            {steps[step].content}
          </p>
        </div>
        <div className="flex justify-between">
          <button
            onClick={() => step > 0 ? setStep(step - 1) : onComplete()}
            className="px-4 py-2 text-neutral-600 hover:text-neutral-800"
          >
            {step === 0 ? "Skip" : "Back"}
          </button>
          <button
            onClick={() => step < steps.length - 1 ? setStep(step + 1) : onComplete()}
            className="px-4 py-2 bg-neutral-800 text-white rounded-md hover:bg-neutral-700 flex items-center gap-2"
          >
            {step === steps.length - 1 ? "Start Playing" : "Next"}
            <ChevronRight size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

const TrustGame = () => {
  const [gameState, setGameState] = useState('character-select');
  const [character, setCharacter] = useState(null);
  const [playerName, setPlayerName] = useState('');
  const [aiName, setAiName] = useState('');
  const [scores, setScores] = useState({ player: 100, ai: 100 });
  const [roundResult, setRoundResult] = useState('');
  const [gameHistory, setGameHistory] = useState([]);
  const [showTutorial, setShowTutorial] = useState(false);
  const [winner, setWinner] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [selectedCharacterKey, setSelectedCharacterKey] = useState(null);

  useEffect(() => {
    const hasPlayed = localStorage.getItem('trustGameHasPlayed');
    if (hasPlayed) {
      setShowOnboarding(false);
    }
  }, []);

  const handleOnboardingComplete = () => {
    setShowOnboarding(false);
    localStorage.setItem('trustGameHasPlayed', 'true');
  };

  const getAiChoice = () => {
    const random = Math.random();

    switch (character.strategy) {
      case 'defects more when winning':
        return scores.ai > scores.player ? random < 0.7 ? 'defect' : 'cooperate' : random < 0.5 ? 'defect' : 'cooperate';
      case 'forgiving but remembers betrayals':
        if (gameHistory.length && gameHistory[gameHistory.length - 1].playerChoice === 'defect') {
          return random < 0.7 ? 'defect' : 'cooperate';
        }
        return random < 0.7 ? 'cooperate' : 'defect';
      case 'usually cooperates but betrayal hurts deeply':
        const betrayals = gameHistory.filter(move => move.playerChoice === 'defect').length;
        return betrayals > 2 ? 'defect' : random < 0.8 ? 'cooperate' : 'defect';
      case 'mostly defects but random cooperation':
        return random < 0.2 ? 'cooperate' : 'defect';
      case 'cooperates with consistent partners':
        const cooperations = gameHistory.filter(move => move.playerChoice === 'cooperate').length;
        return cooperations > gameHistory.length / 2 ? 'cooperate' : random < 0.5 ? 'cooperate' : 'defect';
      default:
        return random < 0.5 ? 'cooperate' : 'defect';
    }
  };

 const handleChoice = (choice) => {
    if (winner) return;

    const aiChoice = getAiChoice();
    let result = '';
    let playerDelta = 0;
    let aiDelta = 0;
    let roundWinner = null;

    if (choice === 'cooperate' && aiChoice === 'cooperate') {
      playerDelta = 30;
      aiDelta = 30;
      result = `Both gained 30 points! ${character.messages.cooperate[Math.floor(Math.random() * character.messages.cooperate.length)]}`;
    } else if (choice === 'defect' && aiChoice === 'defect') {
      playerDelta = -10;
      aiDelta = -10;
      result = `Both lost 10 points! ${character.messages.defect[Math.floor(Math.random() * character.messages.defect.length)]}`;
    } else if (choice === 'cooperate' && aiChoice === 'defect') {
      playerDelta = -20;
      aiDelta = 40;
      roundWinner = aiName;
      result = `${aiName} won this round! (+40/-20) ${character.messages.defect[Math.floor(Math.random() * character.messages.defect.length)]}`;
    } else {
      playerDelta = 40;
      aiDelta = -20;
      roundWinner = playerName;
      result = `${playerName} won this round! (+40/-20) ${character.messages.cooperate[Math.floor(Math.random() * character.messages.cooperate.length)]}`;
    }

    const newPlayerScore = Math.max(0, scores.player + playerDelta);
    const newAiScore = Math.max(0, scores.ai + aiDelta);

    setScores({
      player: newPlayerScore,
      ai: newAiScore
    });

    if (newPlayerScore <= 0 || newAiScore <= 0) {
      setWinner(newPlayerScore <= 0 ? aiName : playerName);
    }

    setRoundResult(result);
    setGameHistory(prev => [...prev, {
      round: prev.length + 1,
      playerChoice: choice,
      aiChoice,
      playerDelta,
      aiDelta,
      result,
      winner: roundWinner
    }]);
  };

  const resetGame = () => {
    setGameState('character-select');
    setScores({ player: 100, ai: 100 });
    setGameHistory([]);
    setWinner(null);
    setRoundResult('');
    setPlayerName('');
    setAiName('');
    setCharacter(null);
    setSelectedCharacterKey(null);
  };

  const renderCharacterSelect = () => (
    <div className="p-4 sm:p-6">
      <div className="mb-8">
        <h1 className="text-center text-xl sm:text-2xl font-normal text-neutral-800">
          Choose Your Opponent
        </h1>
      </div>
      <div className="grid gap-4">
        {Object.entries(characters).map(([key, char]) => (
          <div key={key}
            className="border border-neutral-200 rounded-lg p-4 bg-white cursor-pointer hover:border-neutral-400 transition-colors"
            onClick={() => {
              setSelectedCharacterKey(key);
              setCharacter(char);
              setGameState('name-select');
            }}
          >
            <h2 className="font-medium text-neutral-800 mb-2">{char.name}</h2>
            <p className="text-sm text-neutral-600">{char.description}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const renderNameSelect = () => (
    <div className="p-4 sm:p-6">
      <div className="mb-8">
        <h1 className="text-center text-xl sm:text-2xl font-normal text-neutral-800">
          Name Your Players
        </h1>
      </div>
      <div className="max-w-md mx-auto space-y-6">
        <div>
          <label className="block text-sm font-medium text-neutral-700 mb-2">Your Name</label>
          <input
            type="text"
            placeholder="Enter your name..."
            className="w-full p-3 border border-neutral-200 rounded-md bg-white"
            value={playerName}
            onChange={(e) => setPlayerName(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-neutral-700 mb-2">Opponent's Name</label>
          <input
            type="text"
            placeholder="Name your opponent..."
            className="w-full p-3 border border-neutral-200 rounded-md bg-white"
            value={aiName}
            onChange={(e) => setAiName(e.target.value)}
          />
        </div>
        <div className="pt-4">
          <button
            onClick={() => {
              if (!playerName.trim() || !aiName.trim()) {
                alert("Please enter both names to continue!");
                return;
              }
              setGameState('playing');
            }}
            className="w-full p-3 bg-neutral-800 text-white rounded-md hover:bg-neutral-700 transition-colors"
          >
            Start Game
          </button>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setGameState('character-select');
              setCharacter(null);
              setSelectedCharacterKey(null);
            }}
            className="text-neutral-600 hover:text-neutral-800"
          >
            ← Choose Different Character
          </button>
        </div>
      </div>
    </div>
  );

  const renderGame = () => (
    <div className="p-4 sm:p-6">
      <div className="relative mb-8">
        <button
          onClick={() => setShowTutorial(true)}
          className="absolute right-0 top-0 text-neutral-500 hover:text-neutral-700"
        >
          <Info size={20} />
        </button>
        <h1 className="text-center text-xl sm:text-2xl font-normal text-neutral-800 mb-2">
          {character.name}
        </h1>
      </div>

      <div className="flex justify-between items-center mb-8 sm:mb-12 px-2 sm:px-4 md:px-12">
        <div className="text-center flex-1">
          <MinimalFigure isAi={false} name={playerName} />
          <div className="mt-2 sm:mt-4 font-light text-base sm:text-lg text-neutral-800">
            {scores.player}
          </div>
        </div>

        <div className="h-px w-16 sm:w-32 bg-neutral-300" />

        <div className="text-center flex-1">
          <MinimalFigure isAi={true} name={aiName} />
          <div className="mt-2 sm:mt-4 font-light text-base sm:text-lg text-neutral-800">
            {scores.ai}
          </div>
        </div>
      </div>

      {winner ? (
        <div className="text-center mb-8 sm:mb-12">
          <p className="text-lg sm:text-xl font-medium text-neutral-800 mb-4">
            Game Over! {winner} wins!
          </p>
          <button
            onClick={resetGame}
            className="px-6 sm:px-8 py-3 bg-neutral-800 text-white rounded-md hover:bg-neutral-700 transition-colors"
          >
            Play Again
          </button>
        </div>
      ) : (
        <div className="text-center mb-8 sm:mb-12">
          <p className="mb-6 sm:mb-8 text-neutral-700 font-light">
            {roundResult || "Your move..."}
          </p>

          <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-6">
            <button
              onClick={() => handleChoice('cooperate')}
              className="px-6 sm:px-8 py-3 border border-neutral-300 rounded-md text-neutral-700 hover:bg-neutral-50 transition-colors"
            >
              {character.cooperateText}
            </button>
            <button
              onClick={() => handleChoice('defect')}
              className="px-6 sm:px-8 py-3 border border-neutral-300 rounded-md text-neutral-700 hover:bg-neutral-50 transition-colors"
            >
              {character.defectText}
            </button>
          </div>
        </div>
      )}

      <div className="mt-8 sm:mt-12 border-t border-neutral-200 pt-4 sm:pt-6">
        <div className="max-h-32 sm:max-h-40 overflow-y-auto space-y-2 px-2">
          {gameHistory.slice().reverse().map((round, index) => (
            <div key={index} className="text-xs sm:text-sm text-neutral-600 font-light">
              <div className="flex justify-between items-center">
                <span>Round {round.round}</span>
                <span className="text-right">{round.result}</span>
              </div>
              <div className="flex justify-between text-xs text-neutral-500 mt-1">
                <span>
                  {playerName}: {round.playerChoice === 'cooperate' ? character.cooperateText : character.defectText}
                  ({round.playerDelta > 0 ? '+' : ''}{round.playerDelta})
                </span>
                <span>
                  {aiName}: {round.aiChoice === 'cooperate' ? character.cooperateText : character.defectText}
                  ({round.aiDelta > 0 ? '+' : ''}{round.aiDelta})
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-neutral-50 p-2 sm:p-4 md:p-6">
      {showOnboarding && <Onboarding onComplete={handleOnboardingComplete} />}
      {showTutorial && <Tutorial onClose={() => setShowTutorial(false)} />}

      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-sm">
        {gameState === 'character-select' && renderCharacterSelect()}
        {gameState === 'name-select' && renderNameSelect()}
        {gameState === 'playing' && renderGame()}
      </div>
    </div>
  );
};

export default TrustGame;