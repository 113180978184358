import React, { useState, useEffect } from 'react';
import { ThermometerSun, Ruler, Trophy, X, Info, ArrowRight, MapPin } from 'lucide-react';

const calculateDistance = (city1, city2) => {
  const R = 6371; // Earth's radius in km
  const dLat = (city2.lat - city1.lat) * Math.PI / 180;
  const dLon = (city2.lon - city1.lon) * Math.PI / 180;
  const a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(city1.lat * Math.PI / 180) * Math.cos(city2.lat * Math.PI / 180) *
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return Math.round(R * c);
};

const OnboardingModal = ({ onClose }) => {
  const [step, setStep] = useState(0);




  const steps = [
    {
      title: "WELCOME TO CITY CHALLENGE",
      description: "Test your geographical knowledge through distance estimation and weather comparison.",
      image: <MapPin className="w-16 h-16 text-stone-800" />
    },
    {
      title: "DISTANCE MODE",
      description: "Estimate the distance between two cities and earn points based on accuracy.",
      image: <Ruler className="w-16 h-16 text-stone-800" />
    },
    {
      title: "WEATHER MODE",
      description: "Compare historical temperatures between cities and predict which was warmer.",
      image: <ThermometerSun className="w-16 h-16 text-stone-800" />
    }
  ];

  return (
    <div className="fixed inset-0 bg-stone-900/90 flex items-center justify-center p-4 z-50">
      <div className="bg-stone-100 max-w-lg w-full p-8 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-stone-400 hover:text-stone-900"
        >
          <X size={20} />
        </button>

        <div className="space-y-6 mb-8">
          <div className="flex justify-center">{steps[step].image}</div>
          <h2 className="text-xl tracking-[0.2em] text-center">
            {steps[step].title}
          </h2>
          <p className="text-sm tracking-wide text-center text-stone-600">
            {steps[step].description}
          </p>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex gap-2">
            {steps.map((_, i) => (
              <div
                key={i}
                className={`w-2 h-2 rounded-full ${
                  i === step ? 'bg-stone-900' : 'bg-stone-300'
                }`}
              />
            ))}
          </div>
          {step < steps.length - 1 ? (
            <button
              onClick={() => setStep(s => s + 1)}
              className="px-6 py-2 text-xs tracking-[0.2em] border-2 border-stone-900
                hover:bg-stone-900 hover:text-stone-100 transition-colors"
            >
              NEXT
            </button>
          ) : (
            <button
              onClick={onClose}
              className="px-6 py-2 text-xs tracking-[0.2em] bg-stone-900 text-stone-100
                hover:bg-stone-800 transition-colors"
            >
              START GAME
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const Tutorial = ({ show, onClose }) => (
  <div className="fixed bottom-4 right-4 z-40">
    <button
      onClick={onClose}
      className="bg-stone-900 text-stone-100 p-2 rounded-full hover:bg-stone-800"
    >
      <Info size={20} />
    </button>
    {show && (
      <div className="absolute bottom-12 right-0 bg-stone-100 p-4 w-64 border-2 border-stone-900">
        <h3 className="text-sm tracking-[0.2em] mb-4">QUICK GUIDE</h3>
        <ul className="space-y-2 text-sm tracking-wide text-stone-600">
          <li>• Estimate distances in kilometers</li>
          <li>• Compare historical temperatures</li>
          <li>• Score based on accuracy</li>
          <li>• Complete 10 rounds per game</li>
        </ul>
      </div>
    )}
  </div>
);

const Credits = () => (
  <div className="text-center text-xs tracking-wide text-stone-500 mt-12 pb-8">
    <div className="flex items-center justify-center gap-4 mb-4">
      <span className="w-8 h-px bg-stone-300" />

      <span className="w-8 h-px bg-stone-300" />
    </div>
    <div className="space-y-2">
      <p>Cities data provided by <a href="http://www.geonames.org/" target="_blank" rel="noopener noreferrer" className="underline hover:text-stone-800">GeoNames</a></p>
      <p>Weather data powered by <a href="https://open-meteo.com/" target="_blank" rel="noopener noreferrer" className="underline hover:text-stone-800">Open-Meteo</a></p>

    </div>
  </div>
);

const CityGame = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gameState, setGameState] = useState({
    cities: [],
    selectedCities: [null, null],
    round: 1,
    score: 0,
    weatherData: {
      city1: null,
      city2: null
    }
  });
  const [gameMode, setGameMode] = useState('distance');
  const [guess, setGuess] = useState('');
  const [result, setResult] = useState(null);
  const [gameHistory, setGameHistory] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);

  useEffect(() => {
    const hasPlayed = localStorage.getItem('hasPlayedCityGame');
    if (hasPlayed) {
      setShowOnboarding(false);
    }
    initGame();
  }, []);

  const initGame = async () => {
    setLoading(true);
    try {
      // Simulated city data - replace with actual API call
      const cities = [
        { name: "New York", country: "USA", lat: 40.7128, lon: -74.0060 },
        { name: "London", country: "UK", lat: 51.5074, lon: -0.1278 },
        { name: "Tokyo", country: "Japan", lat: 35.6762, lon: 139.6503 },
        { name: "Paris", country: "France", lat: 48.8566, lon: 2.3522 },
        { name: "Sydney", country: "Australia", lat: -33.8688, lon: 151.2093 }
      ];

      setGameState(prev => ({ ...prev, cities }));
      selectNewCities(cities);
    } catch (err) {
      setError('Failed to load city data. Please try again.');
    }
    setLoading(false);
  };

  const selectNewCities = async (cities = gameState.cities) => {
    if (cities.length < 2) return;

    const city1 = cities[Math.floor(Math.random() * cities.length)];
    let city2;
    do {
      city2 = cities[Math.floor(Math.random() * cities.length)];
    } while (city2.name === city1.name);

    // Simulate weather data
    const weather1 = { maxTemp: Math.round(Math.random() * 30), date: new Date().toISOString() };
    const weather2 = { maxTemp: Math.round(Math.random() * 30), date: new Date().toISOString() };

    setGameState(prev => ({
      ...prev,
      selectedCities: [city1, city2],
      weatherData: {
        city1: weather1,
        city2: weather2
      }
    }));
  };

  const handleDistanceGuess = () => {
    const { selectedCities } = gameState;
    if (!selectedCities[0] || !selectedCities[1]) return;

    const actualDistance = calculateDistance(selectedCities[0], selectedCities[1]);
    const guessNum = parseInt(guess);
    const accuracy = Math.abs(guessNum - actualDistance);
    const percentage = Math.max(0, 100 - (accuracy / actualDistance * 100));
    const points = Math.round(percentage);

    setResult({
      actual: actualDistance,
      guess: guessNum,
      points: points
    });

    setGameHistory(prev => [...prev.slice(-4), {
      id: Date.now(),
      type: 'distance',
      selectedCities: selectedCities,
      isCorrect: accuracy <= actualDistance * 0.2,
      points: points
    }]);

    setGameState(prev => ({
      ...prev,
      score: prev.score + points
    }));
  };

  const handleWeatherGuess = (higher) => {
    const { selectedCities, weatherData } = gameState;
    if (!weatherData.city1 || !weatherData.city2) return;

    const isCorrect = higher ?
      (weatherData.city2.maxTemp > weatherData.city1.maxTemp) :
      (weatherData.city2.maxTemp < weatherData.city1.maxTemp);

    const points = isCorrect ? 10 : 0;

    setResult({
      correct: isCorrect,
      points,
      actualTemp1: weatherData.city1.maxTemp,
      actualTemp2: weatherData.city2.maxTemp
    });

    setGameHistory(prev => [...prev.slice(-4), {
      id: Date.now(),
      type: 'weather',
      selectedCities: selectedCities,
      isCorrect,
      points
    }]);

    setGameState(prev => ({
      ...prev,
      score: prev.score + points
    }));
  };

  const handleNextRound = () => {
    if (gameState.round === 10) {
      setGameState(prev => ({
        ...prev,
        round: 1,
        score: 0
      }));
    } else {
      setGameState(prev => ({
        ...prev,
        round: prev.round + 1
      }));
    }

    setGuess('');
    setResult(null);
    selectNewCities();
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-stone-100 flex items-center justify-center">
        <div className="text-center space-y-4">
          <div className="w-6 h-6 border-2 border-stone-900 border-t-transparent rounded-full animate-spin mx-auto" />
          <div className="text-xs tracking-[0.3em] text-stone-600">LOADING</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-stone-100 flex items-center justify-center">
        <div className="text-center space-y-4 max-w-md px-4">
          <div className="text-xl tracking-[0.2em] text-stone-900 mb-4">{error}</div>
          <button
            onClick={initGame}
            className="px-6 py-3 text-xs tracking-[0.3em] border-2 border-stone-900
              hover:bg-stone-900 hover:text-stone-100 transition-colors"
          >
            TRY AGAIN
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-stone-100 p-4 sm:p-8">
      {showOnboarding && (
        <OnboardingModal onClose={() => {
          setShowOnboarding(false);
          localStorage.setItem('hasPlayedCityGame', 'true');
        }} />
      )}
      <Tutorial show={showTutorial} onClose={() => setShowTutorial(!showTutorial)} />


      <div className="max-w-2xl mx-auto space-y-12">
        {/* Header */}
        <header className="mb-16 space-y-8">
          <div className="text-center space-y-2">
            <h1 className="text-3xl text-stone-900 tracking-[0.25em]">
              <span className="block text-sm mb-2 tracking-[0.5em]">THE</span>
              CITY CHALLENGE
              <span className="block text-lg mt-2 tracking-[0.4em] text-stone-600">
                GEOGRAPHY QUIZ
              </span>
            </h1>
            <p className="text-stone-500 tracking-[0.2em] text-sm">
              Round {gameState.round}/10 • Score {gameState.score}
            </p>
          </div>

          <nav className="flex justify-center gap-1">
            <button
              onClick={() => setGameMode('distance')}
              className={`
                px-6 py-3 text-xs tracking-[0.3em] transition-all border-2
                ${gameMode === 'distance'
                  ? 'border-stone-900 bg-stone-900 text-stone-100'
                  : 'border-stone-300 hover:border-stone-900'
                }
              `}
            >
              DISTANCE
            </button>
            <button
              onClick={() => setGameMode('weather')}
              className={`
                px-6 py-3 text-xs tracking-[0.3em] transition-all border-2
                ${gameMode === 'weather'
                  ? 'border-stone-900 bg-stone-900 text-stone-100'
                  : 'border-stone-300 hover:border-stone-900'
                }
              `}
            >
              WEATHER
            </button>
          </nav>
        </header>

        {/* Game Area */}
        <div className="border-2 border-stone-900 p-1">
          {gameMode === 'distance' ? (
            <div className="space-y-6">
              {gameState.selectedCities[0] && gameState.selectedCities[1] && (
                <>
                  <div className="grid grid-cols-2 gap-1">
                    {[gameState.selectedCities[0], gameState.selectedCities[1]].map((city, index) => (
                      <div key={index} className="p-8 sm:p-12 text-center">
                        <h3 className="text-lg tracking-wide mb-2">{city.name}</h3>
                        <p className="text-xs tracking-[0.2em] text-stone-600">
                          {city.country}
                        </p>
                      </div>
                    ))}
                  </div>

                  {!result ? (
                    <div className="p-4 border-t-2 border-stone-900">
                      <div className="flex gap-1">
                        <input
                          type="number"
                          className="flex-1 px-4 py-3 bg-transparent border-2 border-stone-300 focus:border-stone-900 outline-none text-sm tracking-wider"
                          value={guess}
                          onChange={(e) => setGuess(e.target.value)}
                          placeholder="DISTANCE IN KM"
                        />
                        <button
                          onClick={handleDistanceGuess}
                          className="px-8 py-3 bg-stone-900 text-stone-100text-xs tracking-[0.2em]"
                        >
                          GUESS
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="p-4 border-t-2 border-stone-900 space-y-4">
                      <div className="grid grid-cols-3 gap-1 text-center">
                        <div className="p-4">
                          <p className="text-xs tracking-[0.2em] text-stone-600 mb-2">ACTUAL</p>
                          <p className="text-lg tracking-wider">{result.actual} KM</p>
                        </div>
                        <div className="p-4">
                          <p className="text-xs tracking-[0.2em] text-stone-600 mb-2">GUESS</p>
                          <p className="text-lg tracking-wider">{result.guess} KM</p>
                        </div>
                        <div className="p-4">
                          <p className="text-xs tracking-[0.2em] text-stone-600 mb-2">POINTS</p>
                          <p className="text-lg tracking-wider">{result.points}</p>
                        </div>
                      </div>
                      <button
                        onClick={handleNextRound}
                        className="w-full py-3 bg-stone-900 text-stone-100 text-xs tracking-[0.2em]"
                      >
                        {gameState.round === 10 ? 'NEW GAME' : 'NEXT ROUND'}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="space-y-6">
              {gameState.selectedCities[0] && gameState.selectedCities[1] && gameState.weatherData.city1 && (
                <>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="p-8 sm:p-12 text-center">
                      <h3 className="text-lg tracking-wide mb-2">{gameState.selectedCities[0].name}</h3>
                      <p className="text-xs tracking-[0.2em] text-stone-600 mb-4">
                        {gameState.selectedCities[0].country}
                      </p>
                      <p className="text-xl tracking-wider">{gameState.weatherData.city1.maxTemp}°C</p>
                      <p className="text-xs tracking-[0.15em] text-stone-500 mt-2">
                        {new Date(gameState.weatherData.city1.date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="p-8 sm:p-12 text-center">
                      <h3 className="text-lg tracking-wide mb-2">{gameState.selectedCities[1].name}</h3>
                      <p className="text-xs tracking-[0.2em] text-stone-600 mb-4">
                        {gameState.selectedCities[1].country}
                      </p>
                      {!result ? (
                        <div className="space-y-2">
                          <button
                            onClick={() => handleWeatherGuess(true)}
                            className="w-full py-3 bg-stone-900 text-stone-100 text-xs tracking-[0.2em]"
                          >
                            HIGHER
                          </button>
                          <button
                            onClick={() => handleWeatherGuess(false)}
                            className="w-full py-3 border-2 border-stone-900 text-xs tracking-[0.2em]"
                          >
                            LOWER
                          </button>
                        </div>
                      ) : (
                        <>
                          <p className="text-xl tracking-wider">{gameState.weatherData.city2.maxTemp}°C</p>
                          <p className="text-xs tracking-[0.15em] text-stone-500 mt-2">
                            {new Date(gameState.weatherData.city2.date).toLocaleDateString()}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  {result && (
                    <div className="p-4 border-t-2 border-stone-900 space-y-4">
                      <div className="text-center">
                        <p className="text-lg tracking-wider mb-2">
                          {result.correct ? 'CORRECT' : 'INCORRECT'}
                        </p>
                        <p className="text-sm tracking-[0.2em] text-stone-600">
                          {result.points} POINTS
                        </p>
                      </div>
                      <button
                        onClick={handleNextRound}
                        className="w-full py-3 bg-stone-900 text-stone-100 text-xs tracking-[0.2em]"
                      >
                        {gameState.round === 10 ? 'NEW GAME' : 'NEXT ROUND'}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {/* History Section */}
        <div className="pt-8 border-t-2 border-stone-900">
          <h2 className="flex items-center justify-center gap-4 mb-8">
            <span className="w-12 h-px bg-stone-900" />
            <span className="text-xs tracking-[0.3em] text-stone-600">HISTORY</span>
            <span className="w-12 h-px bg-stone-900" />
          </h2>
          <div className="space-y-1">
            {gameHistory.map((round) => (
              <div
                key={round.id}
                className={`
                  p-4 text-xs tracking-[0.15em]
                  ${round.isCorrect
                    ? 'bg-stone-900 text-stone-100'
                    : 'border-2 border-stone-900'
                  }
                `}
              >
                <div className="truncate">
                  {round.selectedCities[0].name} · {round.selectedCities[1].name}
                  <span className="ml-2 opacity-60">
                    {round.points} POINTS
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Credits />
      </div>
    </div>
  );
};

export default CityGame;