// museumApi.js
export const MUSEUMS = {
  met: {
    name: 'The Metropolitan Museum of Art',
    baseUrl: 'https://collectionapi.metmuseum.org/public/collection/v1',
    async fetchArtwork() {
      const response = await fetch(`${this.baseUrl}/objects?hasImages=true`);
      const data = await response.json();
      const randomId = data.objectIDs[Math.floor(Math.random() * data.objectIDs.length)];
      const artworkResponse = await fetch(`${this.baseUrl}/objects/${randomId}`);
      const artwork = await artworkResponse.json();

      return {
        id: artwork.objectID,
        title: artwork.title,
        image: artwork.primaryImage,
        style: artwork.classification || artwork.department,
        artist: artwork.artistDisplayName,
        period: artwork.period,
        date: artwork.objectDate,
        museum: this.name
      };
    }
  },

  cleveland: {
    name: 'The Cleveland Museum of Art',
    baseUrl: 'https://openaccess-api.clevelandart.org',
    async fetchArtwork() {
      const response = await fetch(
        `${this.baseUrl}/api/artworks?has_image=1&limit=1&skip=${Math.floor(Math.random() * 100)}`
      );
      const data = await response.json();
      const artwork = data.data[0];

      return {
        id: artwork.id,
        title: artwork.title,
        image: artwork.images.web.url,
        style: artwork.style?.[0] || artwork.department,
        artist: artwork.creators?.[0]?.description,
        date: artwork.creation_date,
        museum: this.name
      };
    }
  },

  harvard: {
    name: 'Harvard Art Museums',
    baseUrl: 'https://api.harvardartmuseums.org',
    key: '92a78c89-b318-41b0-92d1-55871b9a857e',
    async fetchArtwork() {
      const response = await fetch(
        `${this.baseUrl}/object?apikey=${this.key}&size=1&page=${Math.floor(Math.random() * 100)}&hasimage=1`
      );
      const data = await response.json();
      const artwork = data.records[0];

      return {
        id: artwork.id,
        title: artwork.title,
        image: artwork.primaryimageurl,
        style: artwork.style || artwork.classification,
        artist: artwork.people?.[0]?.name,
        date: artwork.dated,
        museum: this.name
      };
    }
  },

  walters: {
    name: 'The Walters Art Museum',
    baseUrl: 'https://api.thewalters.org/v1',
    async fetchArtwork() {
      const response = await fetch(
        `${this.baseUrl}/objects?pageSize=1&page=${Math.floor(Math.random() * 100)}&hasImage=true`
      );
      const data = await response.json();
      const artwork = data.Items[0];

      return {
        id: artwork.ObjectNumber,
        title: artwork.Title,
        image: artwork.PrimaryImage?.Large,
        style: artwork.Style || artwork.Classification,
        artist: artwork.Creator,
        date: artwork.DateText,
        museum: this.name
      };
    }
  }
};

export const fetchRandomArtwork = async () => {
  const museums = Object.values(MUSEUMS);
  let artwork = null;
  let attempts = 0;
  const maxAttempts = 5;

  while (!artwork && attempts < maxAttempts) {
    try {
      const randomMuseum = museums[Math.floor(Math.random() * museums.length)];
      const result = await randomMuseum.fetchArtwork();

      if (result && result.image && result.style) {
        artwork = result;
      }
    } catch (error) {
      console.error('Error fetching from museum:', error);
    }
    attempts++;
  }

  if (!artwork) {
    throw new Error('Unable to fetch valid artwork after multiple attempts');
  }

  return artwork;
};