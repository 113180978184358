import React, { useState, useEffect } from 'react';
import { Info, X, ChevronRight } from 'lucide-react';
import movieData from './movies.json';

// Game modes configuration
const GAME_MODES = {
  rating: {
    id: 'rating',
    label: 'RATING',
    question: 'WHICH PICTURE RECEIVED HIGHER ACCLAIM?'
  }
};

// Cache size and thresholds
const CACHE_SIZE = 1000;
const RATING_DIFFERENCE_THRESHOLD = 0.5;

// Process film data
const processFilmData = (films) => {
  return films
    .filter(film => film && film.primaryTitle && film.averageRating)
    .map(film => ({
      ...film,
      id: film.primaryTitle.toLowerCase().replace(/[^a-z0-9]+/g, '-'),
      genres: typeof film.genres === 'string' ? film.genres.split(',') : film.genres,
      averageRating: parseFloat(film.averageRating)
    }))
    .filter(film =>
      !isNaN(film.averageRating) &&
      film.genres.length > 0 &&
      film.averageRating > 0
    );
};

// Round creation function
const createRatingRound = (films) => {
  const eligibleFilms = films
    .filter(f => f.averageRating > 0)
    .sort(() => 0.5 - Math.random())
    .slice(0, 2);

  if (eligibleFilms.length < 2) return null;

  if (Math.abs(eligibleFilms[0].averageRating - eligibleFilms[1].averageRating) < RATING_DIFFERENCE_THRESHOLD) {
    return null;
  }

  return {
    type: 'rating',
    films: eligibleFilms,
    question: GAME_MODES.rating.question,
    correct: eligibleFilms[0].averageRating > eligibleFilms[1].averageRating ? 0 : 1
  };
};

// Modal Components
const OnboardingModal = ({ onClose }) => {
  const [step, setStep] = useState(0);

  const steps = [
    {
      id: 'welcome',
      title: "WELCOME TO THE SILVER SCREEN",
      content: "Test your knowledge of cinema history through an engaging series of comparisons.",
      image: "🎬"
    },
    {
      id: 'modes',
      title: "COMPARE FILMS",
      content: "Compare films by their ratings. Each correct answer adds to your score.",
      image: "🎯"
    },
    {
      id: 'streak',
      title: "BUILD YOUR STREAK",
      content: "See how many correct answers you can achieve!",
      image: "⭐"
    }
  ];

  return (
    <div className="fixed inset-0 bg-stone-900/90 flex items-center justify-center p-4 z-50">
      <div className="bg-stone-100 max-w-lg w-full p-8 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-stone-400 hover:text-stone-900"
          aria-label="Close onboarding"
        >
          <X size={20} />
        </button>

        <div className="space-y-6 mb-8">
          <div className="text-center text-4xl mb-4">{steps[step].image}</div>
          <h2 className="text-xl tracking-[0.2em] text-center font-light">
            {steps[step].title}
          </h2>
          <p className="text-sm tracking-wide text-center leading-relaxed text-stone-600">
            {steps[step].content}
          </p>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex gap-2">
            {steps.map((s, i) => (
              <div
                key={`step-${s.id}`}
                className={`w-2 h-2 rounded-full ${
                  i === step ? 'bg-stone-900' : 'bg-stone-300'
                }`}
              />
            ))}
          </div>

          {step < steps.length - 1 ? (
            <button
              onClick={() => setStep(s => s + 1)}
              className="px-6 py-2 text-xs tracking-[0.2em] border-2 border-stone-900
                hover:bg-stone-900 hover:text-stone-100 transition-colors flex items-center gap-2"
            >
              NEXT <ChevronRight size={14} />
            </button>
          ) : (
            <button
              onClick={onClose}
              className="px-6 py-2 text-xs tracking-[0.2em] bg-stone-900 text-stone-100
                hover:bg-stone-800 transition-colors"
            >
              START QUIZ
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const InfoModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-stone-900/90 flex items-center justify-center p-4 z-50">
      <div className="bg-stone-100 max-w-lg w-full p-8 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-stone-400 hover:text-stone-900"
          aria-label="Close info"
        >
          <X size={20} />
        </button>

        <div className="space-y-6">
          <h2 className="text-xl tracking-[0.2em] text-center font-light">HOW TO PLAY</h2>
          <div className="space-y-4 text-sm tracking-wide text-stone-600">
            <p>Compare films by their ratings:</p>
            <ul className="space-y-2 ml-4">
              <li>
                <strong>RATING:</strong> {GAME_MODES.rating.question}
              </li>
            </ul>
            <p>View your previous answers in the history section below.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Component
const SilverScreenQuiz = () => {
  const [currentRound, setCurrentRound] = useState(null);
  const [score, setScore] = useState(0);
  const [cachedFilms, setCachedFilms] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [gameHistory, setGameHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [showInfo, setShowInfo] = useState(false);

  // Initialize game and load initial data
  useEffect(() => {
    const hasPlayed = localStorage.getItem('hasPlayedSilverScreen');
    if (hasPlayed) {
      setShowOnboarding(false);
    }
    loadInitialData();
  }, []);

  const loadInitialData = () => {
    try {
      setLoading(true);
      setError(null);

      const processedMovies = processFilmData(movieData);

      if (processedMovies.length < 2) {
        throw new Error('Not enough valid film data available (minimum 2 required)');
      }

      const randomMovies = processedMovies
        .sort(() => 0.5 - Math.random())
        .slice(0, CACHE_SIZE);

      setCachedFilms(randomMovies);
      getNextRound(randomMovies);
    } catch (error) {
      console.error("Error loading data:", error);
      setError(error.message || 'Failed to load film data. Please check the console for details.');
    } finally {
      setLoading(false);
    }
  };

  const getNextRound = (filmsData = cachedFilms) => {
    try {
      let round = null;
      let attempts = 10;

      while (!round && attempts > 0) {
        round = createRatingRound(filmsData);
        attempts--;
      }

      if (!round) {
        setError('Unable to create a challenging round. Please try again.');
        return;
      }

      setCurrentRound(round);
      setShowAnswer(false);
      setError(null);
    } catch (error) {
      console.error('Error creating round:', error);
      setError('Failed to create new round');
    }
  };

  const handleAnswer = (answer) => {
    if (showAnswer) return;

    const isCorrect = answer === currentRound.correct;

    setShowAnswer(true);
    setGameHistory(prev => [...prev.slice(-4), {
      ...currentRound,
      playerAnswer: answer,
      isCorrect,
      id: Date.now()
    }]);

    if (isCorrect) {
      setScore(s => s + 1);
    }
  };

  const handleNextRound = () => {
    const usedIds = currentRound.films.map(f => f.id);
    setCachedFilms(prev => prev.filter(f => !usedIds.includes(f.id)));
    getNextRound();
  };

  const handleOnboardingClose = () => {
    setShowOnboarding(false);
    localStorage.setItem('hasPlayedSilverScreen', 'true');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-stone-100 flex items-center justify-center">
        <div className="text-center space-y-4">
          <div className="w-6 h-6 border-2 border-stone-900 border-t-transparent rounded-full animate-spin mx-auto" />
          <div className="text-xs tracking-[0.3em] text-stone-600">LOADING PICTURES</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-stone-100 flex items-center justify-center">
        <div className="text-center space-y-4 max-w-md px-4">
          <div className="text-xl tracking-[0.2em] text-stone-900 mb-4">{error}</div>
          <button
            onClick={loadInitialData}
            className="px-6 py-3 text-xs tracking-[0.3em] border-2 border-stone-900
              hover:bg-stone-900 hover:text-stone-100 transition-colors"
          >
            TRY AGAIN
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-stone-100 p-4 sm:p-8 font-mono">
      {showOnboarding && <OnboardingModal onClose={handleOnboardingClose} />}
      <InfoModal show={showInfo} onClose={() => setShowInfo(false)} />

      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <header className="mb-8 sm:mb-16 space-y-8 sm:space-y-12">
          <div className="relative">
            <h1 className="text-2xl sm:text-4xl text-stone-900 text-center tracking-[0.25em] font-light">
              <span className="block text-base sm:text-lg mb-3 tracking-[0.5em]">THE</span>
              SILVER SCREEN
              <span className="block text-lg sm:text-xl mt-3 tracking-[0.4em] opacity-80">
                PICTURE QUIZ
              </span>
            </h1>
            <button
              onClick={() => setShowInfo(true)}
              className="absolute top-0 right-0 p-2 text-stone-400 hover:text-stone-900"
              aria-label="Show game information"
            >
              <Info size={20} />
            </button>
          </div>

          {/* Score Display */}
          <div className="flex justify-center gap-8 sm:gap-12 text-stone-900">
            <div className="text-center">
              <div className="text-xs tracking-[0.3em] mb-2 opacity-60">SCORE</div>
              <div className="text-2xl sm:text-3xl tracking-widest font-light">{score}</div>
            </div>
          </div>
        </header>

        {/* Game Area */}
        {currentRound && (
          <div className="mb-8 sm:mb-16">
            <p className="text-sm text-stone-600 mb-6 sm:mb-8 text-center tracking-[0.2em]">
              {currentRound.question}
            </p>

            <div className="border-2 border-stone-900 p-1">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                {currentRound.films.map((film, i) => (
                  <button
                    key={`film-${film.id}`}
                    onClick={() => handleAnswer(i)}
                    disabled={showAnswer}
                    className={`
                      group p-8 sm:p-12 text-center transition-all h-full flex flex-col items-center justify-center
                      ${showAnswer && i === currentRound.correct
                        ? 'bg-stone-900 text-stone-100'
                        : 'hover:bg-stone-200'}
                    `}
                  >
                    <h3 className="text-lg sm:text-xl mb-4 sm:mb-6 font-light tracking-wide leading-snug">
                      {film.primaryTitle}
                    </h3>
                    <div className="space-y-2 sm:space-y-3 text-xs tracking-[0.2em] opacity-80">
                      {showAnswer && (
                        <>
                          <p>RATING: {film.averageRating.toFixed(1)}</p>
                          <p className="opacity-60">
                            {film.genres.join(' · ')}
                          </p>
                        </>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {/* Next Round Button */}
            {showAnswer && (
              <div className="text-center mt-6">
                <button
                  onClick={handleNextRound}
                  className="px-6 py-3 text-xs tracking-[0.3em] border-2 border-stone-900
                    hover:bg-stone-900 hover:text-stone-100 transition-colors"
                >
                  NEXT ROUND
                </button>
              </div>
            )}
          </div>
        )}

        {/* History */}
        <div className="border-t-2 border-stone-900 pt-6 sm:pt-8">
          <h2 className="flex items-center justify-center gap-4 mb-6 sm:mb-8">
            <span className="w-8 sm:w-12 h-px bg-stone-900" />
            <span className="text-xs tracking-[0.3em] opacity-60">PREVIOUS PICTURES</span>
            <span className="w-8 sm:w-12 h-px bg-stone-900" />
          </h2>
          <div className="space-y-1">
            {gameHistory.map((round) => (
              <div
                key={`history-${round.id}`}
                className={`
                  p-3 sm:p-4 text-xs tracking-[0.15em]
                  ${round.isCorrect
                    ? 'bg-stone-900 text-stone-100'
                    : 'border-2 border-stone-900'}
                `}
              >
                <div className="truncate">
                  {`${round.films[0].primaryTitle} · ${round.films[1].primaryTitle}`}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SilverScreenQuiz;